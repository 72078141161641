import React, { useState } from "react";
import styled from "styled-components";
import {
  SDivInputLogin,
  SDivInputLoginPass,
  SFormLogin,
  SImg,
  STextInputPass,
} from "../components/Form/styles";
import theme from "../config/theme";
import LogoLogin from "../assets/LogoLogin.svg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OutlineEye from "../components/OutlineEye";
import { setPassword } from "../store/reducers/companyFormReducer";

interface ForgotPasswordProps {}
export const SContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const Sh1 = styled.h1`
  text-align: left;
  color: ${theme.text.primary.normal};
  margin-bottom: 1.5rem;
`;

export const Sp = styled.p`
  font-size: 1rem;
  margin-top: 1.4rem;
  color: ${theme.text.primary.normal};
`;

// export const SForm = styled.form``;

export const SButton = styled.button`
  width: 100%;
  color: ${theme.text.secondary.normal};
  background-color: ${(props) =>
    props.disabled ? "transparent" : theme.button.primary.normal};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  margin-right: 0;
  transition: 0.1s linear;
  &:hover {
    background-color: ${theme.button.primary.light};
  }
`;

export const SBackButton = styled(SButton)`
  margin-right: 3rem;
  background-color: ${theme.button.secondary.normal};
  &:hover {
    background-color: ${theme.button.secondary.dark};
  }
`;

export const SInput = styled.input`
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  background-color: ${theme.bg.normal};
  padding: 0.7rem 1.2rem;
  width: 100%;
  margin-bottom: 1rem;
  :focus {
    outline: 1px solid #3490dc;
  }
`;

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [loading, setLoading] = useState(false);
  const [recoveryPage, setRecoveryPage] = useState(false);
  const [newPasswordPage, setNewPassword] = useState(false);
  const [typeInputPassword, setTypeInputPassword] = useState(true);

  const navigate = useNavigate();

  const handleSubmitEmail = async () => {
    try {
      setLoading(true);
      setRecoveryPage(true);
    } catch (erro) {
      toast.error("Email não encontrado!");
      console.log(erro);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    try {
      setLoading(true);
      setNewPassword(true);
    } catch (erro) {
      toast.error("Código inválido!");
      console.log(erro);
    } finally {
      setLoading(false);
    }
  };

  const handleNewPassword = async () => {
    try {
      setLoading(true);
      toast.success("Senha recuperada!");
    } catch (erro) {
      toast.error("Senha inválida!");
      console.log(erro);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SFormLogin
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitEmail();
      }}
    >
      <ToastContainer />
      <SImg src={LogoLogin} alt="" />
      {!recoveryPage ? (
        <>
          <Sp>Insira o email para receber o código de recuperação.</Sp>
          <SDivInputLogin>
            <SInput
              type="email"
              placeholder="Email"
              onChange={(e) => e.target.value}
            />
          </SDivInputLogin>
          <SContainer>
            <SBackButton onClick={() => navigate("/login")}>Voltar</SBackButton>
            <SButton
              disabled={loading ? true : false}
              onClick={() => {
                handleSubmitEmail();
              }}
            >
              {loading ? "Carregando..." : "Enviar"}
            </SButton>
          </SContainer>
        </>
      ) : !newPasswordPage ? (
        <>
          <Sp>Insira o código de recuperação.</Sp>
          <SDivInputLogin>
            <SInput
              type="text"
              placeholder="Código"
              onChange={(e) => e.target.value}
            />
          </SDivInputLogin>
          <SContainer>
            <SBackButton onClick={() => navigate("/login")}>Voltar</SBackButton>
            <SButton
              disabled={loading ? true : false}
              onClick={() => {
                handleVerifyCode();
              }}
            >
              {loading ? "Carregando..." : "Verificar"}
            </SButton>
          </SContainer>
        </>
      ) : (
        <>
          <Sp>Insira sua nova senha.</Sp>
          {/* <SDivInputLogin> */}
          <SDivInputLoginPass>
            <STextInputPass
              value={""}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              type={typeInputPassword ? "password" : "text"}
              required={true}
            />
            <OutlineEye
              setTypeInputPassword={setTypeInputPassword}
              typeInputPassword={typeInputPassword}
            />
          </SDivInputLoginPass>
          {/* </SDivInputLogin> */}
          <SContainer>
            <SBackButton onClick={() => navigate("/login")}>Voltar</SBackButton>
            <SButton
              disabled={loading ? true : false}
              onClick={() => {
                handleNewPassword();
              }}
            >
              {loading ? "Carregando..." : "Confirmar"}
            </SButton>
          </SContainer>
        </>
      )}
    </SFormLogin>
  );
};

export default ForgotPassword;
