import React from "react";
import { SDiv, Sinput, SLabel, SSelect } from "./styles";

interface IInputModalProps {
  field: string;
  inputType?: string | "text";
  selectType?: boolean;
  value?: string | number;
  action?: string;
  active?: string;
  htmlId: string;
  setOnChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  setOnChangeSelect?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
}

const InputModal: React.FC<IInputModalProps> = ({
  field,
  inputType,
  selectType,
  value,
  setOnChange,
  setOnChangeSelect,
  action,
  active,
  htmlId,
}) => {
  return (
    <SDiv>
      <SLabel htmlFor={htmlId}>{field}</SLabel>
      {!selectType ? (
        <Sinput
          type={inputType}
          value={value}
          onChange={setOnChange}
          required={true}
          maxLength={35}
          id={htmlId}
        />
      ) : (
        <SSelect
          defaultValue="default"
          value={value}
          onChange={setOnChangeSelect}
          id={htmlId}
        >
          <option value="default" disabled>
            Escolha uma opção
          </option>
          <option value="true">Ativo</option>
          <option value="false">Inativo</option>
        </SSelect>
      )}
    </SDiv>
  );
};

export default InputModal;
