import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import Button from "../components/Button";
import CompanyForm from "../components/Form/CompanyForm";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import CompaniesFilter from "../components/Table/CompaniesFilter";
import CompanyTable from "../components/Table/CompanyTable";
import Tabs from "../components/Table/Tabs";
import DEFAULTS from "../config/defaults";
import theme from "../config/theme";
import API from "../services/api";
import EventBus from "../services/eventBus";
import { RootState } from "../store";
import {
  initialState,
  nextPage,
  previousPage,
  setCompanies,
  setFilters,
  setPage,
} from "../store/reducers/companiesReducer";
import { resetCompany } from "../store/reducers/companyFormReducer";
import { setCompany } from "../store/reducers/companyReducer";
import handleError from "../utils/handleError";
import { isValidEmail } from "../utils/isValidEmail";

export const SContainer = styled.div``;

export const SErrorMessageContainer = styled.div`
  height: 20px;
`;

export const SErrorMessage = styled.h5`
  padding-top: 10px;
  color: ${theme.message.warning};
`;

export const SHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  h1 {
    font-size: 1.2em;
    font: inherit;
  }
`;

const Companies: React.FC = () => {
  const {
    data: companies,
    filters,
    count,
    admin,
  } = useSelector((state: RootState) => state.companies);
  const { name, email, imagesPerReward, rewardValue, password, token, plan } =
    useSelector((state: RootState) => state.companyForm);

  const accessToken = useSelector((state: RootState) => state.auth.token);
  // modal
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activesTab, setActivesTab] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const fetchCompanies = useCallback(async () => {
    try {
      if (admin) {
        const allCompanies = await API.getManyCompanies(filters);
        dispatch(setCompanies(allCompanies.data.body.data));
      } else if (accessToken) {
        const response = await API.getCompanyByAccessToken(accessToken);
        dispatch(setCompany(response.data.body.data));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, admin, dispatch, filters]);

  useEffect(() => {
    window.document.title = "Betafit - Empresas";
    fetchCompanies();
  }, [fetchCompanies, dispatch, filters, accessToken, admin]);

  useEffect(() => {
    EventBus.on("REFRESH_COMPANIES", () => {
      fetchCompanies();
      toggleTabs("Ativos");
    });

    return () => {
      EventBus.remove("REFRESH_COMPANIES");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCompanies]);

  useEffect(() => {
    setErrorMessage("");
  }, []);

  useEffect(() => {
    dispatch(resetCompany());
    setErrorMessage("");
  }, [dispatch, open]);

  const toggleTabs = (tab: string): void => {
    if (activesTab && tab === "Ativos") return;
    if (!activesTab && tab === "Inativos") return;

    const activate = !activesTab;
    setActivesTab(activate);

    if (activate) {
      dispatch(
        setFilters({
          ...initialState.filters,
          active_only: true,
          // inactive_only: false,
        })
      );
    } else {
      dispatch(
        setFilters({
          ...initialState.filters,
          active_only: false,
          // inactive_only: true,
        })
      );
    }
    EventBus.dispatch("RESET_COMPANIES_FILTERS");
  };

  const goNext = () => {
    dispatch(nextPage());
  };

  const goBack = () => {
    dispatch(previousPage());
  };

  const goPage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    skip: number
  ) => {
    dispatch(setPage(skip));
  };

  if (loading) <div>loading...</div>;

  if (Companies.length === 0) <div>Nenhum usuário registrado.</div>;

  const isAllFieldsFilled = (): boolean => {
    const isNameNotEmpty = !!name.trim();
    const isEmailNotEmpty = !!email.trim();
    const isPassword = !!password.trim();
    const isToken = !!token.trim();
    const isImagesPerReward = !!imagesPerReward;
    const isRewardValue = !!rewardValue;
    const isPlan = !!plan;
    const isPlanNotEmpty = plan !== "none";

    if (
      isNameNotEmpty &&
      isEmailNotEmpty &&
      isPassword &&
      isRewardValue &&
      isImagesPerReward &&
      isToken &&
      isPlan &&
      isPlanNotEmpty
    ) {
      return true;
    }

    return false;
  };

  const handleCreateCompany = async () => {
    const isOneFieldEmpty = !isAllFieldsFilled();
    const isEmailNotValid = !isValidEmail(email);

    if (isOneFieldEmpty) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return;
    }

    if (isEmailNotValid) {
      setErrorMessage("O email não é valido.");
      return;
    }

    const requestBody = {
      name,
      email,
      password,
      rewardValue,
      token,
      imagesPerReward,
      plan: plan,
    };
    try {
      await API.registerCompany(requestBody);

      setOpen(false);
      EventBus.dispatch("REFRESH_COMPANIES");
      toast.success("Empresa criada com sucesso");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusError: String =
          error.response?.data["body"]["errors"] ?? "";

        console.log(statusError);

        if (statusError.includes("already registered on the platform!")) {
          toast.error("Email já registrado na plataforma!");

          return;
        }

        if (statusError.includes("User limit exceeded for company!")) {
          toast.error(
            "Você atingiu o limite máximo de usuários pelo plano contratado."
          );

          return;
        }

        if (statusError.includes("jwt expired")) {
          toast.error("Refaça o login, token expirado.");

          return;
        }
        toast.error("Erro ao criar Empresa");
      }
    }
  };
  return (
    <>
      <SContainer>
        <ToastContainer />
        <Modal
          open={open}
          setOpen={setOpen}
          title="Criar Empresa"
          action={() => {
            handleCreateCompany();
          }}
          actionText={loading ? "Criando..." : "Confirmar"}
        >
          <CompanyForm
            name={name}
            email={email}
            password={password}
            token={token}
            rewardValue={rewardValue}
            imagesPerReward={imagesPerReward}
            plan={plan}
          />

          <SErrorMessageContainer>
            <SErrorMessage>{errorMessage}</SErrorMessage>
          </SErrorMessageContainer>
        </Modal>
        <SHeader>
          <h1>Empresas</h1>
          <Button text="Adicionar Empresa" onClick={() => setOpen(true)} />
        </SHeader>
        <CompaniesFilter />
        <Tabs actives={activesTab} toggleTabs={toggleTabs} />
        <Pagination
          pagination={{
            count: count,
            skip: filters.skip,
            take: filters.take,
            goNext,
            goBack,
            goPage,
          }}
        />
        <CompanyTable
          companies={companies}
          filters={{
            actives: activesTab,
          }}
        />
        {companies.length >
          DEFAULTS.pagination.numberOfItemsForBottomPagination && (
          <Pagination
            pagination={{
              count: count,
              skip: filters.skip,
              take: filters.take,
              goNext,
              goBack,
              goPage,
            }}
          />
        )}
      </SContainer>
    </>
  );
};

export default Companies;
