import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DEFAULTS from "../../config/defaults";
import { ICompany } from "../interfaces";

export interface ICompanyFilter {
  skip: number;
  take: number;
  keyword?: string;
  email?: string;
  active_only: boolean;
}

export interface ICompanyState {
  data: ICompany[];
  admin: boolean;
  count: number;
  filters: ICompanyFilter;
}

export const initialState: ICompanyState = {
  data: [],
  count: 0,
  admin: false,
  filters: {
    skip: DEFAULTS.pagination.skip,
    take: DEFAULTS.pagination.take,
    keyword: "",
    email: "",
    active_only: true,
  },
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setDuplicity: (
      state,
      action: PayloadAction<{
        result: ICompany[];
        count: number;
        admin: boolean;
      }>
    ) => {
      if (!action.payload) return;
      state.data = action.payload.result;
      state.count = action.payload.count;
      state.admin = action.payload.admin;
    },
    setCompanies: (
      state,
      action: PayloadAction<{ result: ICompany[]; count: number }>
    ) => {
      if (!action.payload) return;
      state.data = action.payload.result;
      state.count = action.payload.count;
    },
    setFilters: (state, action: PayloadAction<ICompanyFilter>) => {
      if (!action.payload) return;
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    nextPage: (state) => {
      state.filters.skip += DEFAULTS.pagination.take;
    },
    previousPage: (state) => {
      if (state.filters.skip === 0) return;
      state.filters.skip -= DEFAULTS.pagination.take;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.skip = DEFAULTS.pagination.take * action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.filters.take = action.payload;
    },
  },
});

export const {
  setCompanies,
  setFilters,
  resetFilters,
  nextPage,
  previousPage,
  setPage,
  setTake,
  setDuplicity,
} = companiesSlice.actions;

export default companiesSlice.reducer;
