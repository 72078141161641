import styled from "styled-components";
import theme from "../../config/theme";

interface ISidebarProps {
  openSidebar: boolean;
}

interface ISidebarTextProps {
  active: boolean;
}

export const SNavbar = styled.div``;

export const SNavText = styled.li<ISidebarTextProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  list-style: none;
  height: 60px;

  a {
    text-decoration: none;
    color: black;
    font-size: 1rem;

    line-height: 1.5;
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 100%;
  }

  a:hover {
    background-color: #eee;
  }

  span {
    margin-left: 1.5rem;
    width: 300px;
    font-weight: ${(props) => (!props.active ? 400 : 500)};
    transition: 1s;
  }

  :last-child {
    margin-bottom: 62px;
  }
`;

export const SNavMenu = styled.nav<ISidebarProps>`
  z-index: 3;
  background-color: ${theme.bg.normal};
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${(props) => (props.openSidebar ? `${0}` : `${-300}px`)};
  transition: left 0.3s ease-in;
`;

export const SNavMenuItems = styled.ul`
  width: 100%;
`;

export const SImg = styled.img`
  display: flex;
  position: relative;
  width: 14rem;
  height: 4rem;
  right: 1.4rem;
  cursor: pointer;
`;
