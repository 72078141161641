import React, { useEffect, useState } from "react";
import { TableButton } from "../Button";
import formatUserType from "../../utils/formatUserType";
import formatDate from "../../utils/formatDate";
import { IUser } from "../../store/interfaces";
import {
  SPageContainer,
  STable,
  STBody,
  STd,
  STh,
  STHead,
  STr,
} from "./styles";
import Modal from "../Modal";
import UserForm from "../Form/UserForm";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";
import {
  setActive,
  setEmail,
  setModality,
  setName,
  setUserId,
} from "../../store/reducers/userFormReducer";
import API from "../../services/api";
import EventBus from "../../services/eventBus";
import { toast, ToastContainer } from "react-toastify";
import theme from "../../config/theme";
import { isValidEmail } from "../../utils/isValidEmail";

interface UserTableProps {
  users: IUser[];
  filters: {
    actives: boolean;
  };
}

export const SErrorMessageContainer = styled.div`
  height: 20px;
`;

export const SErrorMessage = styled.h5`
  padding-top: 10px;
  color: ${theme.message.warning};
`;

const UserTable: React.FC<UserTableProps> = ({ users, filters }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { id, name, email, modality, active } = useSelector(
    (state: RootState) => state.userForm
  );
  const company = useSelector((state: RootState) => state.company.data);
  const admin = useSelector((state: RootState) => state.companies.admin);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setErrorMessage("");
  }, [name, email, modality, open]);

  const handleEditAction = async (
    id: string,
    name: string,
    email: string,
    modality: string,
    active: boolean
  ) => {
    // Setando valores do user globalmente
    dispatch(setUserId(id));
    dispatch(setName(name));
    dispatch(setEmail(email));
    dispatch(setModality(modality));
    dispatch(setActive(active));
  };

  const isAllFieldsFilled = (): boolean => {
    const isNameNotEmpty = !!name.trim();
    const isEmailNotEmpty = !!email.trim();
    const isModalityNotEmpty = !!modality.trim();

    if (isNameNotEmpty && isEmailNotEmpty && isModalityNotEmpty) {
      return true;
    }

    return false;
  };

  const handleEditUser = async () => {
    const isOneFieldEmpty = !isAllFieldsFilled();
    const isEmailNotValid = !isValidEmail(email);

    if (isOneFieldEmpty) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return;
    }

    if (isEmailNotValid) {
      setErrorMessage("O email não é valido.");
      return;
    }

    const requestBody = {
      name,
      email,
      modality,
      active,
    };
    try {
      setLoading(true);
      await API.updateUser(id, requestBody);
      setOpen(false);
      EventBus.dispatch("REFRESH_USERS");
      toast.success("Usuário editado com sucesso!");
    } catch (error) {
      toast.error("Erro ao editar usuário.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SPageContainer>
      <ToastContainer />
      <Modal
        open={open}
        setOpen={setOpen}
        title="Editar Usuário"
        action={() => {
          handleEditUser();
        }}
        actionText={loading ? "Atualizando..." : "Confirmar"}
      >
        <UserForm
          name={name}
          email={email}
          modality={modality}
          active={active}
          id={id}
          action="edit"
        />

        <SErrorMessageContainer>
          <SErrorMessage>{errorMessage}</SErrorMessage>
        </SErrorMessageContainer>
      </Modal>
      <STable>
        <STHead>
          <STr>
            <STh>#</STh>
            <STh>Cadastro</STh>
            {admin ? <STh>Empresa</STh> : null}
            <STh>Nome</STh>
            <STh>E-mail</STh>
            <STh>Modalidade</STh>
            <STh>Tipo</STh>
            <STh>Status</STh>
            <STh>Ações</STh>
          </STr>
        </STHead>

        <STBody>
          {users.map((user, index) => (
            <STr key={user.id}>
              <STd>{index + 1}</STd>
              <STd>{formatDate(user.createdAt)}</STd>
              {admin ? <STd>{user.company && user.company.name}</STd> : null}
              <STd>{user && user.name}</STd>
              <STd>{user.email}</STd>
              <STd>{user.modality}</STd>
              <STd>{formatUserType(user.userType)}</STd>
              <STd>{user.active ? "Ativo" : "Inativo"}</STd>
              <STd>
                <TableButton
                  disabled={!admin && !company.active}
                  onClick={() => {
                    handleEditAction(
                      user.id,
                      user.name,
                      user.email,
                      user.modality,
                      user.active
                    );
                    setOpen(true);
                  }}
                  text="Editar"
                />
              </STd>
            </STr>
          ))}
        </STBody>
      </STable>
    </SPageContainer>
  );
};

export default UserTable;
