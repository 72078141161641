import styled from "styled-components";
import theme from "../../config/theme";
import { SInput } from "../Form/TextInput";

export const SDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
`;

export const SLabel = styled.label`
  color: ${theme.text.primary.normal};
  text-align: left;
`;
export const Sinput = styled(SInput)`
  width: 70%;
  background-color: ${theme.bg.alternative};
`;
export const SInputPassword = styled(SInput)`
  border: none;
  width: 90%;

  :focus {
    outline: none;
  }
`;

export const SSelect = styled.select`
  border: none;
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  background-color: ${theme.bg.normal};
  padding: 10px;
  width: 100%;
  width: 70%;
  background-color: ${theme.bg.alternative};
`;

export const SModalContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 15px;
  background-color: ${theme.bg.light};
  border-radius: 4px;
  z-index: 2;

  display: flex;
  flex-direction: column;

  // animation
  @keyframes onModalOpen {
    from {
      opacity: 0;
      top: 45%;
    }
    to {
      opacity: 1;
      top: 50%;
    }
  }

  animation-name: onModalOpen;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
`;

export const SModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;

  @keyframes onOverlayLoad {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  animation-name: onOverlayLoad;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
`;

export const SModalHeader = styled.div`
  border-bottom: 1px solid ${theme.divider.normal};
  padding: 0 0 15px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SModalTitle = styled.h2`
  font-size: 1em;
`;

export const SModalBody = styled.div`
  /* border: 1px solid green; */
  border-bottom: 1px solid ${theme.divider.normal};
  /* height: 100%; */
  padding: 15px 0;
`;

export const SModalActions = styled.div`
  /* border: 1px solid blue; */
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 15px;
  }
`;
