import React, { useEffect, useState } from "react";
import Button from "../Button";
import { ICompany } from "../../store/interfaces";
import {
  SPageContainer,
  STable,
  STBody,
  STd,
  STh,
  STHead,
  STr,
} from "./styles";
import Modal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";

import API from "../../services/api";
import EventBus from "../../services/eventBus";
import { toast, ToastContainer } from "react-toastify";
import theme from "../../config/theme";
import { isValidEmail } from "../../utils/isValidEmail";
import CompanyForm from "../Form/CompanyForm";
import {
  setCompanyId,
  setName,
  setEmail,
  setToken,
  setRewardValue,
  setImagesPerReward,
  setActive,
  setPlanId,
} from "../../store/reducers/companyFormReducer";
import formatDate from "../../utils/formatDate";

interface CompanyTableProps {
  companies: ICompany[];
  filters: {
    actives: boolean;
  };
}

export const SErrorMessageContainer = styled.div`
  height: 20px;
`;

export const SErrorMessage = styled.h5`
  padding-top: 10px;
  color: ${theme.message.warning};
`;

const CompanyTable: React.FC<CompanyTableProps> = ({ companies, filters }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    id,
    name,
    email,
    password,
    token,
    imagesPerReward,
    rewardValue,
    active,
    plan,
  } = useSelector((state: RootState) => state.companyForm);

  useEffect(() => {
    setErrorMessage("");
  }, [name, email, open]);

  const handleEditAction = async (
    id: string,
    name: string,
    email: string,
    active: boolean,
    token: string,
    imagesPerReward: number,
    rewardValue: string,
    plan: string
  ) => {
    // Setando valores do Company globalmente
    dispatch(setCompanyId(id));
    dispatch(setName(name));
    dispatch(setEmail(email));
    dispatch(setToken(token));
    dispatch(setActive(active));
    dispatch(setRewardValue(rewardValue));
    dispatch(setImagesPerReward(imagesPerReward));
    dispatch(setPlanId(plan));
  };

  const handleEditCompany = async () => {
    const isOneFieldEmpty = !isAllFieldsFilled();
    const isEmailNotValid = !isValidEmail(email);

    if (isOneFieldEmpty) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return;
    }

    if (isEmailNotValid) {
      setErrorMessage("O email não é valido.");
      return;
    }

    const requestBody = {
      name,
      email,
      password,
      active,
      rewardValue,
      token,
      imagesPerReward,
      plan: plan,
    };
    try {
      setLoading(true);
      await API.updateCompany(id, requestBody);

      setOpen(false);
      EventBus.dispatch("REFRESH_COMPANIES");
      toast.success("Empresa editado com sucesso");
    } catch (error) {
      toast.error("Erro ao editar Empresa");
    } finally {
      setLoading(false);
    }
  };

  const isAllFieldsFilled = (): boolean => {
    const isNameNotEmpty = !!name.trim();
    const isEmailNotEmpty = !!email.trim();

    if (isNameNotEmpty && isEmailNotEmpty) {
      return true;
    }

    return false;
  };

  const limitColor = (amountUsers: number, usersLimit: number) => {
    return amountUsers === usersLimit - 2
      ? `${theme.text.table.warning}`
      : (amountUsers === usersLimit && `${theme.text.table.limit}`) ||
          `${theme.text.primary.normal}`;
  };

  return (
    <SPageContainer>
      <ToastContainer />
      <Modal
        open={open}
        setOpen={setOpen}
        title="Editar Empresa"
        action={() => {
          handleEditCompany();
        }}
        actionText={loading ? "Atualizando..." : "Confirmar"}
      >
        <CompanyForm
          name={name}
          email={email}
          password={password}
          active={active}
          token={token}
          imagesPerReward={imagesPerReward}
          rewardValue={rewardValue}
          plan={plan}
          action="edit"
          id={id}
        />

        <SErrorMessageContainer>
          <SErrorMessage>{errorMessage}</SErrorMessage>
        </SErrorMessageContainer>
      </Modal>
      <STable>
        <STHead>
          <STr>
            <STh>#</STh>
            <STh>Cadastro</STh>
            <STh>Nome</STh>
            <STh>E-mail</STh>
            <STh>Status</STh>
            <STh>Plano</STh>
            <STh>Usuários</STh>
            <STh>Ações</STh>
          </STr>
        </STHead>

        <STBody>
          {companies.map((company, index) => (
            <STr key={company.id}>
              <STd>{index + 1}</STd>
              <STd>{company.createdAt && formatDate(company.createdAt)}</STd>
              <STd>{company.name}</STd>
              <STd>{company.email}</STd>
              <STd>{company.active ? "Ativo" : "Inativo"}</STd>
              <STd>{company.plan.name}</STd>
              <STd
                limit={limitColor(company.amountUsers, company.plan.usersLimit)}
              >
                {company.amountUsers}/{company.plan.usersLimit}
              </STd>
              <STd>
                <Button
                  onClick={() => {
                    handleEditAction(
                      company.id,
                      company.name,
                      company.email,
                      company.active,
                      company.token,
                      company.imagesPerReward,
                      company.rewardValue,
                      company.plan.id
                    );
                    setOpen(true);
                  }}
                  text="Editar"
                />
              </STd>
            </STr>
          ))}
        </STBody>
      </STable>
    </SPageContainer>
  );
};

export default CompanyTable;
