const theme = {
  text: {
    primary: {
      light: "#",
      normal: "#212529",
      dark: "#",
    },
    secondary: {
      light: "#",
      normal: "#fff",
      dark: "#",
    },
    link: {
      light: "#3490dc",
      normal: "#007bff",
      dark: "#",
    },
    table: {
      limit: "#FF0000",
      warning: "#FF8C00",
    },
  },
  button: {
    primary: {
      light: "#0c5b94",
      normal: "#004576",
      dark: "#",
    },
    secondary: {
      light: "#",
      normal: "#aeb4b9",
      dark: "#7d858d",
    },
  },
  divider: {
    light: "#eee",
    normal: "#d4d4d4",
    dark: "#",
  },
  shadow: {
    light: "#eee",
    normal: "#cbcdd6",
    dark: "#",
  },
  bg: {
    light: "#f2f2f7",
    normal: "#fcfcfc",
    dark: "#dadada",
    alternative: "#f8fafc",
  },
  icon: {
    light: "#fff",
    dark: "#000",
  },
  message: {
    warning: "#d81212",
  },
};

export default theme;
