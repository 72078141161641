import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EventBus from "../../services/eventBus";
import { RootState } from "../../store";
import { setFilters } from "../../store/reducers/companiesReducer";
import Button from "../Button";
import TextInput from "../Form/TextInput";

export const SForm = styled.form`
  /* border: 1px solid red; */
  margin: 10px 0;
  display: flex;
`;

export const SFormBody = styled.div`
  /* border: 1px solid green; */
  display: flex;
  width: 100%;

  input:not(:first-child) {
    margin-left: 15px;
  }
`;

export const SFormAction = styled.div`
  /* border: 1px solid blue; */
  width: fit-content;
  margin-left: 15px;
`;

interface CompaniesFilterProps {}

const CompaniesFilter: React.FC<CompaniesFilterProps> = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.companies.filters);

  useEffect(() => {
    EventBus.on("RESET_COMPANIES_FILTERS", () => {
      setNome("");
      setEmail("");
    });

    return () => {
      EventBus.remove("RESET_COMPANIES_FILTERS");
    };
  }, []);

  const handleSubmitForm = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();

    dispatch(setFilters({ ...filters, keyword: nome, email }));
  };

  return (
    <SForm>
      <SFormBody>
        <TextInput
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          placeholder="Nome"
        />
        <TextInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
      </SFormBody>
      <SFormAction>
        <Button text="Buscar" onClick={handleSubmitForm} />
      </SFormAction>
    </SForm>
  );
};

export default CompaniesFilter;
