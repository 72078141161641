import React, { useState } from "react";
import { TableButton } from "../Button";
import { IAlbum } from "../../store/interfaces";
import {
  SPageContainer,
  STable,
  STBody,
  STd,
  STh,
  STHead,
  STr,
} from "./styles";
import { toast, ToastContainer } from "react-toastify";
import API from "../../services/api";
import formatDate from "../../utils/formatDate";
import Modal from "../../components/Modal";
import EventBus from "../../services/eventBus";
import {
  setRewardId,
  setRewardIsPaid,
} from "../../store/reducers/albumsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import AlbumForm from "../Form/AlbumForm";

interface AlbumTableProps {
  albums: IAlbum[];
  filters?: {
    actives: boolean;
  };
}

const AlbumsTable: React.FC<AlbumTableProps> = ({ albums, filters }) => {
  const dispatch = useDispatch();

  const { rewardIsPaid } = useSelector((state: RootState) => state.albums);
  const company = useSelector((state: RootState) => state.company.data);
  const { admin } = useSelector((state: RootState) => state.companies);
  const [open, setOpen] = useState(false);
  const [albumId, setAlbumId] = useState(0);

  const handleEditAction = (
    albumId: number,
    rewardId: number,
    rewardIsPaid: boolean
  ) => {
    dispatch(setRewardId(rewardId));
    dispatch(setRewardIsPaid(rewardIsPaid));
    setAlbumId(albumId);
  };

  const handleSetAlbumPaid = async () => {
    try {
      await API.togglePaid(albumId);
      setOpen(false);
      EventBus.dispatch("REFRESH_ALBUMS");
      toast.success("Album editado com sucesso");
    } catch (e) {
      toast.error("Erro ao editar Album");
    }
  };
  return (
    <SPageContainer>
      <ToastContainer />
      <Modal
        open={open}
        setOpen={setOpen}
        title={rewardIsPaid ? "Marcar como não pago" : "Marcar como pago"}
        action={() => {
          handleSetAlbumPaid();
        }}
        actionText="Confirmar"
      >
        <AlbumForm text={rewardIsPaid ? "desmarcar" : "marcar"} />
      </Modal>
      <STable>
        <STHead>
          <STr>
            <STh>#</STh>
            <STh>Usuário</STh>
            <STh>Álbum</STh>
            <STh>Completado</STh>
            <STh>Pago</STh>
            <STh>Data criação</STh>
            <STh></STh>
          </STr>
        </STHead>

        <STBody>
          {albums.map((album, index) => (
            <STr key={album.id}>
              <STd>{index + 1}</STd>
              <STd>{album.user.name}</STd>
              <STd>{album.title}</STd>
              <STd>{album.completed ? "Sim" : "Não"}</STd>
              <STd>{album.reward && album.reward.paid ? "Sim" : "Não"}</STd>
              <STd>{formatDate(album.createdAt)}</STd>
              <STd>
                <TableButton
                  disabled={!admin && !company.active}
                  onClick={() => {
                    handleEditAction(
                      album.id,
                      album.reward.id,
                      album.reward.paid
                    );
                    setOpen(true);
                  }}
                  text={
                    album.reward && album.reward.paid
                      ? "Marcar como não Pago"
                      : "Marcar como Pago"
                  }
                />
              </STd>
            </STr>
          ))}
        </STBody>
      </STable>
    </SPageContainer>
  );
};

export default AlbumsTable;
