import styled, { createGlobalStyle } from "styled-components";
import theme from "../config/theme";

interface ISidebarProps {
  openSidebar: boolean;
}
export const GlobalStyle = createGlobalStyle<ISidebarProps>`
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

   *::-webkit-scrollbar {
    width: 7px;
  } 

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.bg.light};
    border-radius: 10px;
  }

  body {
    background: ${theme.bg.alternative}; 
    overflow-y: scroll;
    color: ${theme.text.primary.normal};
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }
  
  body::-webkit-scrollbar-thumb {   
    background-color: lightgray;   
  }

  body::-webkit-scrollbar {
    width: 10px
  } 

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }
`;

export const SOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;

  @keyframes onOverlayLoad {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  animation-name: onOverlayLoad;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
`;
