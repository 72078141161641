import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../store";
import { setFilters } from "../../store/reducers/usersReducer";
import Button from "../Button";
import TextInput from "../Form/TextInput";

export const SForm = styled.form`
  /* border: 1px solid red; */
  margin: 10px 0;
  display: flex;
`;

export const SFormBody = styled.div`
  /* border: 1px solid green; */
  display: flex;
  width: 100%;

  input:not(:first-child) {
    margin-left: 15px;
  }
`;

export const SFormAction = styled.div`
  /* border: 1px solid blue; */
  width: fit-content;
  margin-left: 15px;
`;

interface UsersFilterProps {}

const UsersFilter: React.FC<UsersFilterProps> = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [modalidade, setModalidade] = useState("");

  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.users.filters);

  const isAllFieldsNotEmpty = (): boolean => {
    const isNameNotEmpty = !!nome.trim();
    const isEmailNotEmpty = !!email.trim();
    const isModalidadeNotEmpty = !!modalidade.trim();

    if (isNameNotEmpty || isEmailNotEmpty || isModalidadeNotEmpty) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isAllFieldsNotEmpty()) {
      dispatch(
        setFilters({
          ...filters,
          keyword: nome.trim(),
          email,
          modality: modalidade.trim(),
        })
      );
    }
    // TODO: verificar se é necessário atulizar as dependencias, vsCode que reclamou.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome.trim(), email.trim(), modalidade.trim()]);

  const handleSubmitForm = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();

    if (isAllFieldsNotEmpty()) {
      dispatch(
        setFilters({
          ...filters,
          keyword: nome.trim(),
          email,
          modality: modalidade.trim(),
        })
      );
    }
  };

  return (
    <SForm>
      <SFormBody>
        <TextInput
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          placeholder="Nome"
        />
        <TextInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <TextInput
          value={modalidade}
          onChange={(e) => setModalidade(e.target.value)}
          placeholder="Modalidade"
        />
      </SFormBody>
      <SFormAction>
        <Button text="Buscar" onClick={handleSubmitForm} />
      </SFormAction>
    </SForm>
  );
};

export default UsersFilter;
