import React from "react";
import styled from "styled-components";
import { IconButton } from "../Button";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import PaginationButton from "./PaginationButton";

export const SPaginationContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;

  display: flex;
  justify-content: end;

  button:not(:first-child) {
    margin-left: 5px;
  }
`;

interface IPagination {
  count: number;
  skip: number;
  take: number;
  goNext: () => void;
  goBack: () => void;
  goPage: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    skip: number
  ) => void;
}

interface PaginationProps {
  pagination: IPagination;
}

const Pagination: React.FC<PaginationProps> = ({ pagination }) => {
  const { count, skip, take, goBack, goNext, goPage } = pagination;

  // pagination properties
  const numberOfPages: number = Math.ceil(count / take);
  const lastPage: number = numberOfPages;
  const currentPage: number = (skip / take) + 1;
  const canGoNext: boolean = currentPage + 1 <= lastPage;
  const canGoBack: boolean = currentPage - 1 > 0;

  return (
    <SPaginationContainer>
      <IconButton
        iconComponent={<BsArrowLeft />}
        onClick={goBack}
        disabled={!canGoBack}
      />

      {numberOfPages === 0 && <PaginationButton active={false} number={1} />}

      {Array.from(Array(numberOfPages)).map((_, index) => {
        const page = index + 1;
        return (
          <PaginationButton
            key={page}
            active={page === currentPage}
            number={page}
            onClick={(e) => goPage(e, index)}
          />
        );
      })}

      <IconButton
        iconComponent={<BsArrowRight />}
        onClick={goNext}
        disabled={!canGoNext}
      />
    </SPaginationContainer>
  );
};

export default Pagination;
