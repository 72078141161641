import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, IPlan, IUser } from "../interfaces";

export interface ICompanyState {
  data: {
    id: string | null;
    name: string | null;
    email: string | null;
    password: string | null;
    token: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    rewardValue: string | null;
    active: boolean;
    imagesPerReward: number | null;
    users: IUser[] | null;
    plan: IPlan | null;
  };
}

export const initialState: ICompanyState = {
  data: {
    id: null,
    name: null,
    email: null,
    password: null,
    token: null,
    active: true,
    rewardValue: null,
    imagesPerReward: null,
    users: null,
    plan: null,
    createdAt: null,
    updatedAt: null,
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<ICompany>) => {
      if (!action.payload) return;
      state.data = action.payload;
    },
  },
});

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
