import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DEFAULTS from "../../config/defaults";
import { IAlbum } from "../interfaces";

export interface IAlbumsFilter {
  skip: number;
  take: number;
}

export interface IAlbumsState {
  data: IAlbum[];
  count: number;
  filters: IAlbumsFilter;
  rewardId: number;
  rewardIsPaid: boolean;
}

export const initialState: IAlbumsState = {
  data: [],
  count: 0,
  filters: {
    skip: DEFAULTS.pagination.skip,
    take: DEFAULTS.pagination.take,
  },
  rewardId: 0,
  rewardIsPaid: false
};

export const albumsSlice = createSlice({
  name: 'albums',
  initialState,
  reducers: {
    setAlbums: (state, action: PayloadAction<{ result: IAlbum[], count: number }>) => {
      if (!action.payload) return;
      state.data = action.payload.result;
      state.count = action.payload.count;
    },
    setFilters: (state, action: PayloadAction<IAlbumsFilter>) => {
      if (!action.payload) return;
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    nextPage: (state) => {
      state.filters.skip += DEFAULTS.pagination.take;
    },
    previousPage: (state) => {
      if (state.filters.skip === 0) return;
      state.filters.skip -= DEFAULTS.pagination.take;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.skip = DEFAULTS.pagination.take * action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.filters.take = action.payload;
    },
    setRewardId: (state, action: PayloadAction<number>) => {
      state.rewardId = action.payload;
    },
    setRewardIsPaid: (state, action: PayloadAction<boolean>) => {
      state.rewardIsPaid = action.payload;
    },
  },
});

export const { 
  setAlbums, setFilters, resetFilters, nextPage, previousPage, 
  setPage, setTake, setRewardId,setRewardIsPaid 
} = albumsSlice.actions;

export default albumsSlice.reducer;
