import React, { useCallback, useEffect, useState } from "react";
import { SContainer, SContent, SDiv } from "./styles";
import Navbar from "../Navbar";
import { TextButton, IconTextCompanyButton } from "../Button";
import useAuth from "../../hooks/useAuth";
import Modal from "../Modal";
import CompanyForm from "../Form/CompanyForm";
import { SErrorMessage, SErrorMessageContainer } from "../Table/CompanyTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setActive,
  setCompanyId,
  setEmail,
  setImagesPerReward,
  setName,
  setPlanId,
  setRewardValue,
  setToken,
} from "../../store/reducers/companyFormReducer";
import EventBus from "../../services/eventBus";
import { toast } from "react-toastify";
import API from "../../services/api";
import { isValidEmail } from "../../utils/isValidEmail";
import { FaCog } from "react-icons/fa";
import { setCompany } from "../../store/reducers/companyReducer";
import handleError from "../../utils/handleError";
const Header: React.FC = () => {
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { filters, admin } = useSelector((state: RootState) => state.companies);
  const company = useSelector((state: RootState) => state.company.data);
  const {
    id,
    name,
    email,
    imagesPerReward,
    rewardValue,
    password,
    token,
    plan,
    active,
  } = useSelector((state: RootState) => state.companyForm);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setErrorMessage("");
  }, [name, email, open]);

  // ----------------------------x--------------------------------- //

  const fetchCompanies = useCallback(async () => {
    try {
      if (id) {
        const response = await API.getCompanyById(id);
        dispatch(setCompany(response.data.body.data));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies, dispatch, filters, admin]);

  useEffect(() => {
    EventBus.on("REFRESH_COMPANIES", () => {
      fetchCompanies();
    });

    return () => {
      EventBus.remove("REFRESH_COMPANIES");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCompanies]);

  const handleEditAction = async (
    id: string,
    name: string,
    email: string,
    active: boolean,
    token: string,
    imagesPerReward: number,
    rewardValue: string,
    plan: string
  ) => {
    // Setando valores do Company globalmente
    dispatch(setCompanyId(id));
    dispatch(setName(name));
    dispatch(setEmail(email));
    dispatch(setToken(token));
    dispatch(setActive(active));
    dispatch(setRewardValue(rewardValue));
    dispatch(setImagesPerReward(imagesPerReward));
    dispatch(setPlanId(plan));
  };

  const handleEditCompany = async () => {
    const isOneFieldEmpty = !isAllFieldsFilled();
    const isEmailNotValid = !isValidEmail(email);

    if (isOneFieldEmpty) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return;
    }

    if (isEmailNotValid) {
      setErrorMessage("O email não é valido.");
      return;
    }

    const requestBody = {
      name,
      email,
      active,
      rewardValue,
      token,
      imagesPerReward,
      plan: plan,
    };
    try {
      setLoading(true);
      await API.updateCompany(id, requestBody);
      setOpen(false);
      EventBus.dispatch("REFRESH_COMPANIES");
      toast.success("Empresa editada com sucesso");
    } catch (error) {
      toast.error("Erro ao editar Empresa");
    } finally {
      setLoading(false);
    }
  };

  const isAllFieldsFilled = (): boolean => {
    const isNameNotEmpty = !!name.trim();
    const isEmailNotEmpty = !!email.trim();

    if (isNameNotEmpty && isEmailNotEmpty) {
      return true;
    }

    return false;
  };
  return (
    <SContainer>
      <SContent id="header">
        <Navbar />

        <SDiv>
          {admin ? null : (
            <IconTextCompanyButton
              text={company.name || "Configurações"}
              iconComponent={<FaCog size={16} />}
              onClick={() => {
                handleEditAction(
                  company.id!,
                  company.name!,
                  company.email!,
                  company.active,
                  company.token!,
                  company.imagesPerReward!,
                  company.rewardValue!,
                  company.plan?.id!
                );
                setOpen(true);
              }}
            />
          )}
          <TextButton text="Sair" onClick={logout} />
        </SDiv>
      </SContent>
      <Modal
        open={open}
        setOpen={setOpen}
        title="Edite sua Empresa"
        action={() => {
          handleEditCompany();
        }}
        actionText={loading ? "Atualizando..." : "Confirmar"}
      >
        <CompanyForm
          name={name}
          email={email}
          password={password}
          token={token}
          rewardValue={rewardValue}
          imagesPerReward={imagesPerReward}
          plan={plan}
        />
        <SErrorMessageContainer>
          <SErrorMessage>{errorMessage}</SErrorMessage>
        </SErrorMessageContainer>
      </Modal>
    </SContainer>
  );
};

export default Header;
