import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import appRoutes from "./routes";
import { RootState } from "./store";
import { GlobalStyle, SOverlay } from "./styles/global";
import { toggleSidebar } from "./store/reducers/appReducer";
import ProtectedRoute from "./routes/ProtectedRoute";
import Page from "./pages/Page";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyTerms from "./pages/PrivacyTerms";

const App = () => {
  const openSidebar = useSelector((state: RootState) => state.app.openSidebar);
  const dispatch = useDispatch();

  function MyRedirect(props: any) {
    window.location.replace(props.url);
    return null;
  }

  return (
    <div className="App">
      <GlobalStyle openSidebar={openSidebar} />
      {openSidebar && <SOverlay onClick={() => dispatch(toggleSidebar())} />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/privacy-terms" element={<PrivacyTerms />} />
        {appRoutes.map((el, index) => (
          <Route
            key={index}
            path={el.path}
            element={
              <ProtectedRoute>
                <Page>{el.component}</Page>
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
    </div>
  );
};

export default App;
