import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { SImg, SNavbar, SNavMenu, SNavMenuItems, SNavText } from "./styles";
import sidebarData from "./sidebarData";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../store/reducers/appReducer";
import LogoCor from "../../assets/Logotipo_colorido.svg";
import useScrollLock from "../../hooks/useScrollLock";
import theme from "../../config/theme";
import { IconButton } from "../Button";

export const RouteSelected = (path: string) => {
  const location = useLocation();
  const pathLocation = location.pathname;

  return pathLocation === path;
};

const Navbar: React.FC = () => {
  const openSidebar = useSelector((state: RootState) => state.app.openSidebar);
  const { lockScroll, unlockScroll } = useScrollLock();
  const dispatch = useDispatch();
  useEffect(() => {
    openSidebar ? lockScroll() : unlockScroll();
  }, [openSidebar, lockScroll, unlockScroll]);

  // const admin = useSelector((state: RootState) => state.auth.admin);
  const admin = useSelector((state: RootState) => state.companies.admin);

  return (
    <SNavbar>
      <IconButton
        iconComponent={
          <AiOutlineMenu
            size={32}
            color={theme.text.primary.normal}
            title="Abrir Menu"
          />
        }
        onClick={() => dispatch(toggleSidebar())}
      />
      <SNavMenu openSidebar={openSidebar}>
        <SNavMenuItems>
          <SImg src={LogoCor} alt="Home" />
          {sidebarData.map((item, index) => {
            return (!admin && item.title === "Empresas") ||
              (admin && item.title === "Álbuns") ? null : (
              <SNavText key={index} active={RouteSelected(item.path)}>
                <Link to={item.path} onClick={() => dispatch(toggleSidebar())}>
                  <span>{item.title}</span>
                </Link>
              </SNavText>
            );
          })}
        </SNavMenuItems>
      </SNavMenu>
    </SNavbar>
  );
};

export default Navbar;
