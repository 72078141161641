import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlbumsTable from "../components/Table/AlbumsTable";
import API from "../services/api";
import { RootState } from "../store";
import {
  setAlbums,
  nextPage,
  previousPage,
  setPage,
} from "../store/reducers/albumsReducer";
import handleError from "../utils/handleError";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import DEFAULTS from "../config/defaults";
import EventBus from "../services/eventBus";
import { ToastContainer } from "react-toastify";
import AlbumTabs from "../components/Table/AlbumTabs";

export const SContainer = styled.div``;

export const SHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  h1 {
    font-size: 1.2em;
    font: inherit;
  }
`;

interface AlbumsProp {}

const Albums: React.FC<AlbumsProp> = () => {
  const [loading, setLoading] = useState(true);
  const [showAllTab, setShowAllTab] = useState(true);

  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.company.data);
  const {
    data: albums,
    filters,
    count,
  } = useSelector((state: RootState) => state.albums);
  const fetchCompanyAlbums = useCallback(async () => {
    try {
      if (showAllTab && company.id) {
        const response = await API.getWaitingPaymentAlbums(company.id, filters);
        dispatch(setAlbums(response.data.body.data));
      } else if (!showAllTab && company.id) {
        const response = await API.getAllPaidAlbums(company.id, filters);
        dispatch(setAlbums(response.data.body.data));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [company.id, dispatch, filters, showAllTab]);

  useEffect(() => {
    window.document.title = "Betafit - Álbuns";
    fetchCompanyAlbums();
  }, [company, fetchCompanyAlbums, filters, showAllTab]);

  useEffect(() => {
    EventBus.on("REFRESH_ALBUMS", () => {
      fetchCompanyAlbums();
      toggleTabs("waiting-payment");
    });

    return () => {
      EventBus.remove("REFRESH_ALBUMS");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCompanyAlbums]);

  const toggleTabs = (tab: string): void => {
    if (showAllTab && tab === "waiting-payment") return;
    if (!showAllTab && tab === "all-paid") return;

    const showAll = !showAllTab;
    setShowAllTab(showAll);
  };

  const goNext = () => {
    dispatch(nextPage());
  };

  const goBack = () => {
    dispatch(previousPage());
  };

  const goPage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    skip: number
  ) => {
    dispatch(setPage(skip));
  };

  if (loading) <div>loading...</div>;

  if (albums.length === 0) <div>Nenhum álbum registrado.</div>;

  return (
    <>
      <SContainer>
        <ToastContainer />
        <SHeader>
          <h1>Álbuns</h1>
        </SHeader>
        <AlbumTabs showAll={showAllTab} toggleTabs={toggleTabs} />
        <Pagination
          pagination={{
            count: count,
            skip: filters.skip,
            take: filters.take,
            goNext,
            goBack,
            goPage,
          }}
        />
        <AlbumsTable
          albums={albums}
          filters={{
            actives: showAllTab,
          }}
        />
        {albums.length >
          DEFAULTS.pagination.numberOfItemsForBottomPagination && (
          <Pagination
            pagination={{
              count: count,
              skip: filters.skip,
              take: filters.take,
              goNext,
              goBack,
              goPage,
            }}
          />
        )}
      </SContainer>
    </>
  );
};

export default Albums;
