import axios, { AxiosResponse } from "axios";
import qs from "qs";

const BASE_API = process.env.REACT_APP_BASE_API_URL;
const REQUEST_TIMEOUT_MS =
  Number(process.env.REACT_APP_REQUEST_TIMEOUT_MS) || 30_000;

const getToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return null;
  }
  return "Bearer " + token;
};

export const axiosGET = (
  endpoint: string,
  body = {}
): Promise<AxiosResponse<any, any>> => {
  return axios({
    method: "GET",
    url: `${BASE_API + endpoint}${
      Object.keys(body).length === 0 ? "" : "?"
    }${qs.stringify(body)}`,
    headers: {
      Accept: "application/json",
      Authorization: `${getToken()}`,
    },
    timeout: REQUEST_TIMEOUT_MS,
  });
};

export const axiosPOST = (
  endpoint: string,
  body = {}
): Promise<AxiosResponse<any, any>> => {
  return axios({
    method: "POST",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${getToken()}`,
    },
    data: {
      ...body,
    },
    timeout: REQUEST_TIMEOUT_MS,
  });
};

export const axiosPUT = (
  endpoint: string,
  body = {}
): Promise<AxiosResponse<any, any>> => {
  return axios({
    method: "PUT",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${getToken()}`,
    },
    data: {
      ...body,
    },
    timeout: REQUEST_TIMEOUT_MS,
  });
};

export const axiosPATCH = (
  endpoint: string,
  body = {}
): Promise<AxiosResponse<any, any>> => {
  return axios({
    method: "PATCH",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      Authorization: `${getToken()}`,
    },
    data: body,
    timeout: REQUEST_TIMEOUT_MS,
  });
};

export const axiosDELETE = (
  endpoint: string
): Promise<AxiosResponse<any, any>> => {
  return axios({
    method: "DELETE",
    url: BASE_API + endpoint,
    headers: {
      Accept: "application/json",
      Authorization: `${getToken()}`,
    },
    timeout: REQUEST_TIMEOUT_MS,
  });
};
