import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../config/theme";
import API from "../../services/api";
import { RootState } from "../../store";
import { IPlan } from "../../store/interfaces";
import {
  setActive,
  setEmail,
  setImagesPerReward,
  setName,
  setPassword,
  setPlanId,
  setRewardValue,
  setToken,
} from "../../store/reducers/companyFormReducer";
import InputModal from "../Modal/inputsModal";
import { SInputPassword } from "../Modal/styles";
import OutlineEye from "../OutlineEye";
import { SDivGroupCompanyPass, SDivInputCompanyPass, SLabel } from "./styles";

interface CompanyFormProps {
  id?: string;
  name?: string;
  email?: string;
  password?: string;
  token?: string;
  plan?: IPlan | string;
  planId?: string;
  planName?: string;
  rewardValue?: string;
  imagesPerReward?: number;
  active?: boolean;
  action?: string;
}

export const SSelect = styled.select`
  border: none;
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  background-color: ${theme.bg.normal};
  padding: 10px;
  width: 100%;
  background-color: ${theme.bg.alternative};
  margin-top: 10px;
`;

const CompanyForm: React.FC<CompanyFormProps> = ({
  name,
  email,
  active,
  token,
  imagesPerReward,
  rewardValue,
  action,
  plan,
}) => {
  const { admin } = useSelector((state: RootState) => state.companies);
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [typeInputPassword, setTypeInputPassword] = useState(true);

  useEffect(() => {
    const getPlan = async () => {
      const response = await API.getPlans();
      const result = response.data.body.data.result;
      setPlans(result);
    };
    getPlan();
  }, [dispatch]);

  return (
    <form>
      <InputModal
        htmlId="name"
        field="Nome"
        value={name}
        setOnChange={(e) => {
          dispatch(setName(e.target.value));
        }}
      />
      <InputModal
        htmlId="email"
        field="Email"
        value={email}
        setOnChange={(e) => {
          dispatch(setEmail(e.target.value));
        }}
      />
      <InputModal
        htmlId="rewardValue"
        field="Premiação"
        inputType="text"
        value={rewardValue}
        setOnChange={(e) => {
          dispatch(setRewardValue(e.target.value));
        }}
      />

      <InputModal
        htmlId="imagesPerReward"
        field="Qtd. Images"
        inputType="number"
        value={imagesPerReward}
        setOnChange={(e) => {
          dispatch(setImagesPerReward(parseInt(e.target.value, 10)));
        }}
      />
      <InputModal
        htmlId="token"
        field="Token"
        value={token}
        setOnChange={(e) => {
          dispatch(setToken(e.target.value));
        }}
      />
      {admin ? (
        <SDivGroupCompanyPass>
          <SLabel>Senha</SLabel>
          <SDivInputCompanyPass>
            <SInputPassword
              type={typeInputPassword ? "password" : "text"}
              value={undefined}
              onChange={(e) => {
                dispatch(setPassword(e.target.value));
              }}
            />
            <OutlineEye
              setTypeInputPassword={setTypeInputPassword}
              typeInputPassword={typeInputPassword}
            />
          </SDivInputCompanyPass>
        </SDivGroupCompanyPass>
      ) : null}
      {action === "edit" ? (
        <InputModal
          htmlId="active"
          field="Ativo"
          value={active ? "true" : "false"}
          selectType={true}
          setOnChangeSelect={(e) => {
            dispatch(setActive(e.target.value === "true"));
          }}
        />
      ) : null}
      {admin ? (
        <SSelect
          id="type"
          value={plan?.toString()}
          onChange={(e) => {
            dispatch(setPlanId(e.target.value));
          }}
          defaultValue="none"
        >
          <option value="none" selected>
            Escolha um plano
          </option>
          {plans.map((plan: IPlan) => (
            <>
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            </>
          ))}
        </SSelect>
      ) : null}
    </form>
  );
};

export default CompanyForm;
