import { format } from "date-fns";

const formatDate = (ISODate: string): string => {
  return format(new Date(ISODate), "dd/MM/yyyy");
};

export default formatDate;

export const formatDateToRanking = (ISODate: string): string => {
  return format(new Date(ISODate), "dd/MM");
};
