import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DEFAULTS from "../../config/defaults";
import { IUser } from "../interfaces";

export interface IUsersFilter {
  skip: number;
  take: number;
  keyword?: string;
  email?: string;
  modality?: string;
  active_only: boolean;
  inactive_only: boolean;
}

export interface IUsersState {
  data: IUser[];
  count: number;
  filters: IUsersFilter;
}

export const initialState: IUsersState = {
  data: [],
  count: 0,
  filters: {
    skip: DEFAULTS.pagination.skip,
    take: DEFAULTS.pagination.take,
    keyword: "",
    email: "",
    modality: "",
    active_only: true,
    inactive_only: false,
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<{ result: IUser[], count: number }>) => {
      if (!action.payload) return;
      state.data = action.payload.result;
      state.count = action.payload.count;
    },
    setFilters: (state, action: PayloadAction<IUsersFilter>) => {
      if (!action.payload) return;
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    nextPage: (state) => {
      state.filters.skip += DEFAULTS.pagination.take;
    },
    previousPage: (state) => {
      if (state.filters.skip === 0) return;
      state.filters.skip -= DEFAULTS.pagination.take;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.skip = DEFAULTS.pagination.take * action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.filters.take = action.payload;
    },
  },
});

export const { setUsers, setFilters, resetFilters, nextPage, previousPage, setPage, setTake } = usersSlice.actions;

export default usersSlice.reducer;
