import { IoMdWarning } from "react-icons/io";
import { Link } from "react-router-dom";
import theme from "../../config/theme";
import { SWarning } from "./styles";

const Warning: React.FC = () => {
  return (
    <SWarning>
      <IoMdWarning size={24} color={theme.icon.light} />
      <Link to="/dashboard">
        <h3>
          Sua conta se encontra inativa, clique aqui e ative seu plano agora
          mesmo
        </h3>
      </Link>
    </SWarning>
  );
};

export default Warning;
