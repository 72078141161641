import React from "react";
import styled from "styled-components";
import theme from "../../config/theme";

export const STabContainer = styled.div`
  display: flex;
  height: 50px;
  margin: 10px 0;
  border-bottom: 1px solid ${theme.divider.normal};
`;

export const STab = styled.div<{ showAll?: boolean }>`
  display: flex;
  border: ${(props) =>
    props.showAll ? `1px solid ${theme.divider.normal}` : "none"};
  border-bottom: ${(props) =>
    props.showAll ? "none" : `1px solid ${theme.divider.normal}`};
  border-radius: 4px 4px 0 0;
  justify-content: center;
  align-items: center;
  width: 180px;
  cursor: pointer;
  color: ${(props) => (props.showAll ? "" : theme.text.link.normal)};
  transform: translateY(1px);
  background-color: ${theme.bg.normal};
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${theme.bg.light};
  }
`;

interface AlbumTabsProps {
  showAll: boolean;
  toggleTabs: (tab: string) => void;
}

const AlbumTabs: React.FC<AlbumTabsProps> = ({ showAll, toggleTabs }) => {
  return (
    <STabContainer>
      <STab showAll={showAll} onClick={() => toggleTabs("waiting-payment")}>
        Pendentes
      </STab>
      <STab showAll={!showAll} onClick={() => toggleTabs("all-paid")}>
        Pagos
      </STab>
    </STabContainer>
  );
};

export default AlbumTabs;
