import styled from "styled-components";
import theme from "../../config/theme";

export const SFormLogin = styled.form`
  margin: 5rem auto;
  border: 1px solid rgba(0, 0, 0, 0.5);
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  max-width: 414px;
  border-radius: 20px;

  padding: 3.5rem;
`;

export const SDivInputLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.9rem;
  position: relative;
  border-radius: 8px;
`;

export const SDivInputLoginPass = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.9rem;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  padding-right: 1rem;

  &:focus-within {
    outline: 1px solid #3490dc;
  }
`;

export const SDivInputCompanyPass = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  margin-left: 4.1rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  width: 69%;
  background-color: ${theme.bg.alternative};

  :focus-within {
    outline: 1px solid #3490dc;
  }
`;

export const SDivGroupCompanyPass = styled.div`
  margin-left: 0.2rem;
  align-items: center;
  display: flex;
`;

export const STextInputPass = styled.input`
  border: none;
  background-color: ${theme.bg.normal};
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  float: left;

  :focus {
    outline: none;
  }
`;

export const STextInput = styled.input`
  background-color: ${theme.bg.alternative};
  display: block;
  width: 80%;
  padding: 0.3rem 0.9rem;
  line-height: 1.8;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.4rem;
  font-size: 11pt;

  :focus {
    outline: 1px solid #3490dc;
  }
`;

export const SImg = styled.img`
  display: block;
  width: 100%;
  cursor: pointer;
`;

export const SDivPrivacyPolicy = styled.div`
  margin: 29px 0 29px 0;
  font-size: 11pt;
`;

export const SCheckBoxInput = styled.input`
  cursor: pointer;
  margin: 5px;
`;

export const SLabel = styled.label`
  cursor: pointer;
`;

export const SDivLoginButton = styled.div`
  button {
    width: 100%;
  }
`;

export const SDivForgotPassword = styled.div`
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
  padding: 0.9rem;
`;

export const SSpan = styled.span`
  margin-right: 4px;
`;

export const SLinkForgotPassword = styled.a`
  color: ${theme.text.primary.normal};
  font-size: 11pt;
  opacity: 0.8;
  text-decoration: none;
  padding: 0.7%;
  margin-bottom: 1rem;
`;

export const SLink = styled.a`
  color: ${theme.text.link.light};
  opacity: 0.7;
  text-decoration: underline;
`;
