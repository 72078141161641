import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LoginForm from "../components/Form/LoginForm";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  useEffect(() => {
    window.document.title = "Betafit - Login";
  }, []);
  return (
    <>
      <ToastContainer />
      <LoginForm />
    </>
  );
};

export default Login;
