import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "../components/Table/UserTable";
import API from "../services/api";
import { RootState } from "../store";
import {
  setFilters,
  setUsers,
  nextPage,
  previousPage,
  setPage,
  initialState,
} from "../store/reducers/usersReducer";
import handleError from "../utils/handleError";
import styled from "styled-components";
import Tabs from "../components/Table/Tabs";
import { TableButton } from "../components/Button";
import UsersFilter from "../components/Table/UsersFilter";
import Pagination from "../components/Pagination";
import DEFAULTS from "../config/defaults";
import EventBus from "../services/eventBus";
import Modal from "../components/Modal";
import UserForm from "../components/Form/UserForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import { resetUser } from "../store/reducers/userFormReducer";
import theme from "../config/theme";
import { isValidEmail } from "../utils/isValidEmail";

export const SContainer = styled.div``;

export const SErrorMessageContainer = styled.div`
  height: 20px;
`;

export const SErrorMessage = styled.h5`
  padding-top: 10px;
  color: ${theme.message.warning};
`;

export const SHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  h1 {
    font-size: 1.2em;
    font: inherit;
  }
`;

interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
  const [loading, setLoading] = useState(true);
  const [activesTab, setActivesTab] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const company = useSelector((state: RootState) => state.company.data);
  const admin = useSelector((state: RootState) => state.companies.admin);
  const { name, email, modality, companyId } = useSelector(
    (state: RootState) => state.userForm
  );

  const {
    data: users,
    filters,
    count,
  } = useSelector((state: RootState) => state.users);

  // modal
  const [open, setOpen] = useState(false);
  const fetchCompanyUsers = useCallback(async () => {
    try {
      if (admin) {
        const response = await API.getManyUsers(filters);
        dispatch(setUsers(response.data.body.data));
      } else if (company.id) {
        const response = await API.getUsersByCompany(company.id, filters);
        dispatch(setUsers(response.data.body.data));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [admin, company.id, dispatch, filters]);

  useEffect(() => {
    window.document.title = "Betafit - Usuários";
    fetchCompanyUsers();
  }, [admin, company.id, dispatch, filters, fetchCompanyUsers]);

  useEffect(() => {
    EventBus.on("REFRESH_USERS", () => {
      fetchCompanyUsers();
      toggleTabs("Ativos");
    });

    return () => {
      EventBus.remove("REFRESH_USERS");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCompanyUsers]);

  useEffect(() => {
    setErrorMessage("");
  }, [name, email, modality]);

  useEffect(() => {
    dispatch(resetUser());
    setErrorMessage("");
  }, [dispatch, open]);

  const toggleTabs = (tab: string): void => {
    if (activesTab && tab === "Ativos") return;
    if (!activesTab && tab === "Inativos") return;

    const activate = !activesTab;
    setActivesTab(activate);
    if (activate) {
      dispatch(
        setFilters({
          ...initialState.filters,
          active_only: true,
          inactive_only: false,
        })
      );
    } else {
      dispatch(
        setFilters({
          ...initialState.filters,
          active_only: false,
          inactive_only: true,
        })
      );
    }
    EventBus.dispatch("RESET_USERS_FILTERS");
  };

  const goNext = () => {
    dispatch(nextPage());
  };

  const goBack = () => {
    dispatch(previousPage());
  };

  const goPage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    skip: number
  ) => {
    dispatch(setPage(skip));
  };

  if (loading) <div>loading...</div>;

  if (users.length === 0) <div>Nenhum usuário registrado.</div>;

  const handleCreateUser = async () => {
    const isOneFieldEmpty = !isAllFieldsFilled();
    const isEmailNotValid = !isValidEmail(email);

    if (isOneFieldEmpty) {
      setErrorMessage("Todos os campos são obrigatórios.");
      return;
    }

    if (isEmailNotValid) {
      setErrorMessage("O email não é valido.");
      return;
    }

    const requestBody = {
      name,
      email,
      modality,
      company: admin ? companyId.toString() : company.id,
      active: true,
    };
    try {
      await API.registerUser(requestBody);

      setOpen(false);
      EventBus.dispatch("REFRESH_USERS");
      toast.success("Usuário criado com sucesso!");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusError: String =
          error.response?.data["body"]["errors"] ?? "";
        console.log(statusError);
        if (statusError.includes("already registered on the platform!")) {
          toast.error("Email já registrado na plataforma!");

          return;
        }

        if (statusError.includes("User limit exceeded for company!")) {
          toast.error(
            "Você atingiu o limite máximo de usuários pelo plano contratado."
          );

          return;
        }

        toast.error("Erro ao criar usuário.");
      }
    }
  };

  const isAllFieldsFilled = (): boolean => {
    const isNameNotEmpty = !!name.trim();
    const isEmailNotEmpty = !!email.trim();
    const isModalityNotEmpty = !!modality.trim();
    const isCompanyNotEmpty = admin ? companyId || company.id === "none" : true;

    if (
      isNameNotEmpty &&
      isEmailNotEmpty &&
      isModalityNotEmpty &&
      isCompanyNotEmpty
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <SContainer>
        <ToastContainer />
        <Modal
          open={open}
          setOpen={setOpen}
          title="Criar Usuário"
          action={() => {
            handleCreateUser();
          }}
          actionText={loading ? "Criando..." : "Confirmar"}
        >
          <UserForm
            name={name}
            email={email}
            modality={modality}
            companyId={companyId.toString()}
          />

          <SErrorMessageContainer>
            <SErrorMessage>{errorMessage}</SErrorMessage>
          </SErrorMessageContainer>
        </Modal>
        <SHeader>
          <h1>{admin ? "Admin" : `Usuários da ${company.name}`}</h1>
          <TableButton
            disabled={!admin && !company.active}
            text="Criar Usuário"
            onClick={() => setOpen(true)}
          />
        </SHeader>
        <UsersFilter />
        <Tabs actives={activesTab} toggleTabs={toggleTabs} />
        <Pagination
          pagination={{
            count: count,
            skip: filters.skip,
            take: filters.take,
            goNext,
            goBack,
            goPage,
          }}
        />
        <UserTable
          users={users}
          filters={{
            actives: activesTab,
          }}
        />
        {users.length >
          DEFAULTS.pagination.numberOfItemsForBottomPagination && (
          <Pagination
            pagination={{
              count: count,
              skip: filters.skip,
              take: filters.take,
              goNext,
              goBack,
              goPage,
            }}
          />
        )}
      </SContainer>
    </>
  );
};

export default Users;
