import styled from "styled-components";
import theme from "../../config/theme";

export const SButton = styled.button`
  color: ${theme.text.secondary.normal};
  background-color: ${(props) =>
    props.disabled ? "transparent" : theme.button.primary.normal};
  padding: 10px 14px;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  transition: 0.1s linear;
  &:hover {
    background-color: ${theme.button.primary.light};
  }
`;

export const STableButton = styled(SButton)<{ disabled: boolean | undefined }>`
  background-color: ${(props) =>
    props.disabled
      ? theme.button.secondary.normal
      : theme.button.primary.normal};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? theme.button.secondary.dark : theme.button.primary.dark};
  }
`;

export const SSecondaryButton = styled(SButton)`
  color: ${theme.text.secondary.normal};
  background-color: ${(props) =>
    props.disabled ? "transparent" : theme.button.secondary.normal};

  &:hover {
    background-color: ${theme.button.secondary.dark};
  }
`;

export const STextButton = styled(SButton)`
  color: ${(props) =>
    props.disabled ? theme.shadow.normal : theme.text.link.normal};
  background-color: transparent;

  &:hover {
    background-color: ${theme.shadow.light};
  }
`;

export const SDiv = styled.div`
  display: flex;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const STextCompanyButton = styled(SButton)`
  border: none;
  border-radius: 4px;
  transition: 0.1s linear;
  display: flex;
  color: ${(props) =>
    props.disabled ? theme.shadow.normal : theme.text.primary.normal};
  background-color: transparent;

  &:hover {
    background-color: ${theme.shadow.light};
  }
`;

export const SFabButton = styled(SButton)`
  color: ${(props) =>
    props.disabled ? theme.shadow.normal : theme.text.link.normal};
  background-color: transparent;
  border-radius: 50%;

  &:hover {
    background-color: ${theme.shadow.light};
  }
`;

export const SFabTextButton = styled(SButton)`
  border-radius: 50%;
`;

export const SLoginButton = styled(SButton)<{ disabled: boolean | undefined }>`
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 6.3rem;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.disabled
      ? theme.button.secondary.normal
      : theme.button.primary.normal};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? theme.button.secondary.dark : theme.button.primary.dark};
  }
`;

export const STextButtonPrivacyPolicy = styled(STextButton)`
  color: ${theme.text.link.light};
  text-decoration: underline;
`;
