export interface ISideBarData {
  title: string;
  path: string;
}
const sidebarData: ISideBarData[] = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Empresas",
    path: "/companies",
  },
  {
    title: "Usuários",
    path: "/users",
  },
  {
    title: "Álbuns",
    path: "/albums",
  },
];

export default sidebarData;
