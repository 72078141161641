import React, { useCallback, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styled from "styled-components";
import theme from "../config/theme";
import API from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../store/reducers/usersReducer";
import { RootState } from "../store";
import BarChart from "../components/BarChart";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const SContainer = styled.div`
  padding-top: 3rem;
  height: 70vh;
`;

export const SDivColumn = styled.div`
  background-color: ${theme.bg.normal};
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 5px;
  overflow-x: scroll;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

export const SDivList = styled.div`
  background-color: ${theme.bg.normal};
  padding: 1.2rem;
  padding-left: 1.3rem;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

export const SThead = styled.thead`
  background-color: ${theme.bg.alternative};
`;
export const STbody = styled.tbody``;

export const STable = styled.table`
  text-align: center;

  border-collapse: collapse;
  width: 100%;
`;

export const STHead = styled.thead`
  position: fixed;
`;

export const STBody = styled.tbody``;

export const STr = styled.tr`
  border-bottom: 1px solid ${theme.shadow.light};
`;

export const STh = styled.th`
  font-size: 12pt;
  text-align: left;
  padding: 10px 0;

  &:last-child {
    text-align: center;
  }
`;

export const STd = styled.td`
  text-align: left;
  padding: 10px 0;

  &:last-child {
    text-align: center;
  }
`;

export const SSpacer = styled.div`
  height: 60px;
`;

export const SProfile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.company.data);
  const admin = useSelector((state: RootState) => state.companies.admin);
  // const admin = useSelector((state: RootState) => state.auth.admin);
  const { data: users } = useSelector((state: RootState) => state.users);
  const fetchUsersByCompany = useCallback(async () => {
    try {
      if (admin) {
        const response = await API.getManyUsersRanking();

        if (response.data.body.data)
          dispatch(setUsers(response.data.body.data));
      } else if (company.id) {
        const response = await API.getUsersByCompanyRanking(company.id);

        if (response.data.body.data)
          dispatch(setUsers(response.data.body.data));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }, [admin, company.id, dispatch]);

  useEffect(() => {
    window.document.title = "Betafit - Dashboard";
    fetchUsersByCompany();
  }, [company.id, dispatch, fetchUsersByCompany]);

  return (
    <>
      <SContainer>
        <SDivColumn>
          <BarChart />
        </SDivColumn>
        <SSpacer />
        <SDivList>
          <STable>
            <SThead>
              <STr>
                <STh>Posição</STh>
                <STh>Foto</STh>
                <STh>Participante</STh>
                <STh>Email</STh>
                <STh>Modalidade</STh>
                <STh>Qtd. Fotos</STh>
              </STr>
            </SThead>
            <STbody>
              {users.map((user, index) => (
                <STr key={user.id}>
                  <STd>{index + 1}º</STd>
                  <STd>
                    <SProfile
                      src={user.profileImage}
                      width={45}
                      height={40}
                    ></SProfile>
                  </STd>
                  <STd>{user.name}</STd>
                  <STd>{user.email}</STd>
                  <STd>{user.modality}</STd>
                  <STd>{user.amountImages}</STd>
                </STr>
              ))}
            </STbody>
          </STable>
        </SDivList>
        <SSpacer />
      </SContainer>
    </>
  );
};

export default Dashboard;
