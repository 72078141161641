import React from "react";
import styled from "styled-components";
import theme from "../../config/theme";

export const STabContainer = styled.div`
  display: flex;
  height: 50px;
  margin: 10px 0;
  border-bottom: 1px solid ${theme.divider.normal};
`;

export const STab = styled.div<{ active: boolean }>`
  display: flex;
  border: ${(props) =>
    props.active ? `1px solid ${theme.divider.normal}` : "none"};
  border-bottom: ${(props) =>
    props.active ? "none" : `1px solid ${theme.divider.normal}`};
  border-radius: 4px 4px 0 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  cursor: pointer;
  color: ${(props) => (props.active ? "" : theme.text.link.normal)};
  transform: translateY(1px);
  background-color: ${theme.bg.alternative};
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${theme.bg.light};
  }
`;

interface TabsProps {
  actives: boolean;
  toggleTabs: (tab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ actives, toggleTabs }) => {
  return (
    <STabContainer>
      <STab active={actives} onClick={() => toggleTabs("Ativos")}>
        Ativos
      </STab>
      <STab active={!actives} onClick={() => toggleTabs("Inativos")}>
        Inativos
      </STab>
    </STabContainer>
  );
};

export default Tabs;
