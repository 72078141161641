const DEFAULTS = {
  defaultPath: "/dashboard",
  headerHeight: "60px",
  pagination: {
    skip: 0,
    take: 20,
    numberOfItemsForBottomPagination: 20,
  },
};

export default DEFAULTS;
