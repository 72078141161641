import { axiosDELETE, axiosGET, axiosPOST, axiosPUT } from "./axios";

interface IQueryParams {
  keyword?: string;
  skip?: number;
  take?: number;
  show_inactives?: string;
}

export interface IUserForm {
  name?: string;
  email?: string;
  modality?: string;
  active?: boolean;
  company?: string | null;
}

export interface ICompanyForm {
  name?: string;
  email?: string;
  password?: string;
  token?: string;
  rewardValue?: string;
  imagesPerReward?: number;
  active?: boolean;
}

export interface ILogin {
  email?: string;
  password?: string;
}
export interface IRewardForm {
  id?: number;
  user?: IUserForm;
  comment?: string;
  likes?: number;
  paid?: boolean;
}

const API = {
  // App
  ping: () => {
    return axiosGET(`/ping`);
  },

  // Login
  loginCompany: (body: ILogin) => {
    return axiosPOST(`/auth/login/company`, body);
  },
  verifyToken: (token: string) => {
    return axiosGET(`/auth/login/admin/decode-token/${token}`);
  },
  verifyAdmin: (adminId: string | null) => {
    return axiosGET(`/users/verify-admin/${adminId}`);
  },
  // Company
  getCompanyById: (id: string) => {
    return axiosGET(`/companies/${id}`);
  },
  getManyCompanies: (query?: IQueryParams) => {
    return axiosGET(`/companies`, query);
  },
  simpleGetManyCompanies: () => {
    return axiosGET(`/companies/simple-findAll`);
  },

  getCompanyByAccessToken: (token: string | null) => {
    return axiosGET(`/companies/accessToken/${token}`);
  },
  registerCompany: (body: ICompanyForm) => {
    return axiosPOST(`/auth/register/company`, body);
  },
  updateCompany: (id?: string, body?: ICompanyForm) => {
    return axiosPUT(`/companies/${id}`, body);
  },

  // Ranking to Dashboard
  getUsersByCompanyRanking: (id: string) => {
    return axiosGET(`/users/company/${id}/ranking?date=${new Date()}`);
  },

  getImagesByCompany: (id: string | null) => {
    return axiosGET(`/users/imagesDate/company/${id}`);
  },
  getImagesByAllCompany: (token: string | null) => {
    return axiosGET(`/users/imagesDate/all-company/${token}`);
  },
  // User
  getManyUsers: (query?: IQueryParams) => {
    return axiosGET(`/users`, query);
  },
  getManyUsersRanking: (query?: IQueryParams) => {
    return axiosGET(`/users/all-users/ranking`, query);
  },
  getUserById: (id: string) => {
    return axiosGET(`/users/${id}`);
  },
  deleteUserById: (id: string) => {
    return axiosDELETE(`/users/${id}`);
  },
  registerUser: (body: IUserForm) => {
    return axiosPOST(`/users/create-in-adm`, body);
  },
  updateUser: (id?: string, body?: IUserForm) => {
    return axiosPUT(`/users/${id}`, body);
  },
  getUsers: () => {
    return axiosGET(`/users`);
  },
  getUsersByCompany: (companyId: string, query?: IQueryParams) => {
    return axiosGET(`/users/company/${companyId}`, query);
  },

  // Plan
  getPlans: () => {
    return axiosGET("/plans");
  },

  //UserAlbum
  getCompanyAlbumsByCompany: (
    companyId: string | null,
    query?: IQueryParams
  ) => {
    return axiosGET(`/user-albums/company/${companyId}`, query);
  },
  getWaitingPaymentAlbums: (companyId: string, query?: IQueryParams) => {
    return axiosGET(`/user-albums/waiting-payment/${companyId}`, query);
  },
  getAllPaidAlbums: (companyId: string, query?: IQueryParams) => {
    return axiosGET(`/user-albums/all-paid/${companyId}`, query);
  },

  //Reward
  updateReward: (id?: number, body?: IRewardForm) => {
    return axiosPUT(`/rewards/${id}`, body);
  },
  togglePaid: (id?: number) => {
    return axiosPUT(`/rewards/togglePaid/${id}`);
  },
};

export default API;
