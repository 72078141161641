import React, { Fragment, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import API from "../services/api";

interface ProtectedRouteProps {}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  // useEffect(() => {
  //   console.log(localToken);

  //   if (localToken) dispatch(setToken(localToken));
  // }, [dispatch, token]);

  useEffect(() => {
    const getByToken = async () => {
      if (token) {
        try {
          const response = await API.verifyToken(token);
          if (response.data.body.data.success) {
            return;
          } else {
            localStorage.clear();
            navigate("/login");
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (!token) navigate("/login");
    };
    getByToken();
  }, [navigate, token]);

  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
