import styled from "styled-components";
import DEFAULTS from "../../config/defaults";
import theme from "../../config/theme";

export const SWarning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  background-color: ${theme.message.warning};
  margin-top: ${DEFAULTS.headerHeight};

  h3 {
    padding-top: 0.1rem;
    color: ${theme.text.secondary.normal};
    font-size: 1.2em;
    font: inherit;
    display: block;
    margin-left: 0.5rem;
  }
`;
