import React, { ChangeEventHandler } from "react";
import styled from "styled-components";
import theme from "../../config/theme";

export const SInput = styled.input`
  border: 1px solid ${theme.divider.normal};
  border-radius: 4px;
  background-color: ${theme.bg.normal};
  padding: 10px;
  width: 100%;

  :focus {
    outline: 1px solid #3490dc;
  }
`;

interface TextInputProps {
  value: string;
  maxLength?: number;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string | undefined;
  type?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  maxLength = 50,
  onChange,
  required,
  placeholder,
  type,
}) => {
  return (
    <SInput
      type={type}
      maxLength={maxLength}
      value={value}
      onChange={onChange}
      required={required || false}
      placeholder={placeholder}
    />
  );
};

export default TextInput;
