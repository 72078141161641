import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import theme from "../../config/theme";
import API from "../../services/api";
import { RootState } from "../../store";
import { formatDateToRanking } from "../../utils/formatDate";

interface IImagesAndDates {
  amountImages: number[];
  dates: string[];
}

const BarChart: React.FC = () => {
  const company = useSelector((state: RootState) => state.company.data);
  const { token } = useSelector((state: RootState) => state.auth);
  const admin = useSelector((state: RootState) => state.companies.admin);
  const [imagesAndDates, setImagesAndDates] = useState<IImagesAndDates>();

  const fetchImagesAndDatesByCompany = useCallback(async () => {
    try {
      if (admin) {
        const response = await API.getImagesByAllCompany(token);
        setImagesAndDates(response.data.body.data);
      } else if (company.id) {
        const response = await API.getImagesByCompany(company.id);
        setImagesAndDates(response.data.body.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }, [admin, company.id, token]);

  useEffect(() => {
    fetchImagesAndDatesByCompany();
  }, [company.id, fetchImagesAndDatesByCompany]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `Quantidade de Fotos por Dia - Ano ${new Date().getFullYear()}`,
      },
    },
  };
  const data = {
    labels: imagesAndDates?.dates
      .map((item) => formatDateToRanking(item))
      .reverse(),
    datasets: [
      {
        label: "Fotos",
        data: imagesAndDates?.amountImages,
        backgroundColor: `${theme.button.primary.normal}`,
      },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

export default BarChart;
