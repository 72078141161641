import React, { MouseEventHandler } from "react";
import { FabButton, FabTextButton } from "../Button";

interface PaginationButtonProps {
  active: boolean;
  number: number;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  active,
  onClick,
  number,
}) => {
  if (active) {
    return <FabTextButton text={number} onClick={onClick} />;
  }

  return <FabButton text={number} onClick={onClick} />;
};

export default PaginationButton;
