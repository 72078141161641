import React from "react";
import styled from "styled-components";
import theme from "../../config/theme";

export const SLabel = styled.label`
  color: ${theme.text.primary.normal};
  text-align: left;
  font-size: 14pt;
`;

interface AlbumFormProps {
  text: string;
}

const AlbumForm: React.FC<AlbumFormProps> = ({ text }) => {
  return <SLabel>{`Deseja ${text} esse álbum como pago?`}</SLabel>;
};

export default AlbumForm;
