import React, { Fragment } from "react";
import Header from "../components/Header";
import styled from "styled-components";
import DEFAULTS from "../config/defaults";
import Warning from "../components/Warning";
import { RootState } from "../store";
import { useSelector } from "react-redux";

const SPageBody = styled.div`
  padding-top: ${DEFAULTS.headerHeight};
  flex-direction: column;
  display: flex;
  width: 80%;
  min-width: 800px;
  margin: 20px auto;
`;

interface PageProps {}

const Page: React.FC<PageProps> = ({ children }) => {
  const company = useSelector((state: RootState) => state.company.data);
  const { admin } = useSelector((state: RootState) => state.companies);

  return (
    <Fragment>
      <Header />
      {!admin && !company.active && <Warning />}
      <SPageBody>{children}</SPageBody>
    </Fragment>
  );
};

export default Page;
