import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DEFAULTS from "../config/defaults";
import API from "../services/api";
import { RootState } from "../store";
import { setToken } from "../store/reducers/authReducer";
import { setDuplicity } from "../store/reducers/companiesReducer";
import { setCompany } from "../store/reducers/companyReducer";

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);
  const admin = useSelector((state: RootState) => state.companies.admin);
  const [loading, setLoading] = useState(false);
  const { filters } = useSelector((state: RootState) => state.companies);

  const refresh = useCallback(async () => {
    const localAdmin = localStorage.getItem("userAdmin");
    if (admin || localAdmin) {
      const responseAdmin = await API.verifyAdmin(localAdmin);
      if (responseAdmin.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
      const response = await API.getManyCompanies(filters);
      dispatch(
        setDuplicity({
          admin: true,
          count: response.data.body.data.count,
          result: response.data.body.data.result,
        })
      );
    } else if (token) {
      const response = await API.getCompanyByAccessToken(token);
      dispatch(setCompany(response.data.body.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
  }, [dispatch, token, navigate, admin, refresh]);

  const login = async (email: string, password: string) => {
    setLoading(true);

    // login
    try {
      const response = await API.loginCompany({ email, password });
      if (
        !response.data.body.data.company?.userType &&
        response.data.body.data.company?.userType !== 1
      ) {
        const responseToken = response.data.body.data?.accessToken;
        const responseCompany = response.data.body.data?.company;

        responseToken && dispatch(setToken(responseToken));
        responseCompany && dispatch(setCompany(responseCompany));
        responseToken && localStorage.setItem("accessToken", responseToken);

        navigate(DEFAULTS.defaultPath);
        setLoading(false);
        return response.status;
      }

      // populando dados para o admin
      const responseToken = response.data.body.data?.accessToken;

      if (responseToken) {
        dispatch(setToken(responseToken));
        localStorage.setItem("accessToken", responseToken);
      }

      localStorage.setItem("userAdmin", response.data.body.data.company.id);

      const allCompanies = await API.getManyCompanies(filters);

      dispatch(
        setDuplicity({
          admin: true,
          count: allCompanies.data.body.data.count,
          result: allCompanies.data.body.data.result,
        })
      );

      navigate(DEFAULTS.defaultPath);
      setLoading(false);
      return response.status;
    } catch (error) {
      localStorage.clear();
    }
  };

  const logout = () => {
    dispatch(setToken(null));
    dispatch(
      setDuplicity({
        admin: false,
        count: 0,
        result: [],
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  return {
    loading,
    token,
    login,
    logout,
  };
};

export default useAuth;
