import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface OutlineEyeProps {
  typeInputPassword: boolean;
  setTypeInputPassword: (type: boolean) => void;
}

const OutlineEye: React.FC<OutlineEyeProps> = ({
  typeInputPassword,
  setTypeInputPassword,
}) => {
  return (
    <>
      {!typeInputPassword ? (
        <AiOutlineEye
          onClick={() => setTypeInputPassword(!typeInputPassword)}
          size={20}
        />
      ) : (
        <AiOutlineEyeInvisible
          onClick={() => setTypeInputPassword(!typeInputPassword)}
          size={20}
        />
      )}
    </>
  );
};
export default OutlineEye;
