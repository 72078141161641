import React from "react";
import styled from "styled-components";

const SPageBody = styled.div`
  font-family: "Nunito", sans-serif;
  padding: 1em 10%;
  color: rgb(88, 88, 88);

  h1 {
    color: #212b35;
    font-weight: 600;
    margin-top: 15px;
  }

  p {
    margin-top: 20px;
  }
  ul {
    margin: 15px 0px 0px 25px;
  }
`;

const PrivacyTerms: React.FC = () => {
  return (
    <SPageBody>
      <h1>Betafit Termos &amp; Condições</h1>
      <p>
        Ao baixar ou usar o aplicativo, esses termos se aplicarão
        automaticamente a você - portanto, certifique-se de lê-los atentamente
        antes de usar o aplicativo. Você não tem permissão para copiar ou
        modificar o aplicativo, qualquer parte do aplicativo ou nossas marcas
        registradas de forma alguma. Você não tem permissão para tentar extrair
        o código-fonte do aplicativo e também não deve tentar traduzir o
        aplicativo para outros idiomas ou fazer versões derivadas. O aplicativo
        em si e todas as marcas registradas, direitos autorais, direitos de
        banco de dados e outros direitos de propriedade intelectual relacionados
        a ele ainda pertencem à Betabit.
      </p>
      <p>
        A Betabit está empenhada em garantir que o aplicativo seja o mais útil e
        eficiente possível. Por esse motivo, nos reservamos o direito de fazer
        alterações no aplicativo ou cobrar por seus serviços, a qualquer momento
        e por qualquer motivo. Nunca cobraremos pelo aplicativo ou seus serviços
        sem deixar bem claro para você exatamente pelo que está pagando.
      </p>
      <p>
        O aplicativo Betafit armazena e processa dados pessoais que você nos
        forneceu, para fornecer nosso Serviço. É sua responsabilidade manter seu
        telefone e o acesso ao aplicativo seguros. Portanto, recomendamos que
        você não faça o jailbreak ou root no seu telefone, que é o processo de
        remoção de restrições e limitações de software impostas pelo sistema
        operacional oficial do seu dispositivo. Isso pode tornar seu telefone
        vulnerável a malware/vírus/programas maliciosos, comprometer os recursos
        de segurança do seu telefone e pode significar que o aplicativo Betafit
        não funcionará corretamente ou não funcionará.
      </p>
      <div>
        <p>
          O aplicativo usa serviços de terceiros que declaram seus Termos e
          Condições.
        </p>
        <p>
          Link para Termos e Condições de provedores de serviços terceirizados
          usados ​​pelo aplicativo
        </p>
        <ul>
          <li>
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/crashlytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
      </div>
      <p>
        Você deve estar ciente de que há certas coisas pelas quais a Betabit não
        se responsabiliza. Certas funções do aplicativo exigirão que o
        aplicativo tenha uma conexão ativa com a Internet. A conexão pode ser
        Wi-Fi ou fornecida pelo seu provedor de rede móvel, mas a Betabit não
        pode se responsabilizar pelo aplicativo não funcionar em plena
        funcionalidade se você não tiver acesso ao Wi-Fi e não tiver nenhum dos
        seus dados sobrado subsídio.
      </p>
      <p></p>
      <p>
        Se você estiver usando o aplicativo fora de uma área com Wi-Fi,
        lembre-se de que os termos do contrato com seu provedor de rede móvel
        ainda se aplicam. Como resultado, você pode ser cobrado pela sua
        operadora de celular pelo custo dos dados pela duração da conexão
        durante o acesso ao aplicativo ou outras cobranças de terceiros. Ao usar
        o aplicativo, você aceita a responsabilidade por tais cobranças,
        incluindo cobranças de dados de roaming se você usar o aplicativo fora
        de seu território (ou seja, região ou país) sem desativar o roaming de
        dados. Se você não for o pagador de contas do dispositivo em que está
        usando o aplicativo, saiba que presumimos que você recebeu permissão do
        pagador de contas para usar o aplicativo.
      </p>
      <p>
        Na mesma linha, a Betabit nem sempre pode se responsabilizar pela
        maneira como você usa o aplicativo, ou seja, você precisa garantir que
        seu dispositivo permaneça carregado - se ficar sem bateria e você não
        puder ativá-lo para aproveitar o Serviço, a Betabit não poderá aceitar a
        responsabilidade.
      </p>
      <p>
        No que diz respeito à responsabilidade da Betabit pelo uso do
        aplicativo, quando você estiver usando o aplicativo, é importante ter em
        mente que, embora nos esforcemos para garantir que ele esteja sempre
        atualizado e correto, contamos com terceiros para fornecer informações
        para que possamos disponibilizá-las para você. A Betabit não se
        responsabiliza por qualquer perda, direta ou indireta, que você
        experimente como resultado de confiar totalmente nesta funcionalidade do
        aplicativo.
      </p>
      <p>
        Em algum momento, podemos querer atualizar o aplicativo. O aplicativo
        está atualmente disponível no Android – os requisitos para o Android (e
        para quaisquer sistemas adicionais para os quais decidirmos estender a
        disponibilidade do aplicativo) podem mudar, e você precisará baixar as
        atualizações se quiser continuar usando o aplicativo . A Betabit não
        promete que sempre atualizará o aplicativo para que seja relevante para
        você e/ou funcione com a versão Android e iOS que você instalou no seu
        dispositivo. No entanto, você promete sempre aceitar as atualizações do
        aplicativo quando oferecidas a você. Também podemos querer parar de
        fornecer o aplicativo e encerrar o uso dele a qualquer momento sem aviso
        de rescisão a você. A menos que lhe digamos o contrário, após qualquer
        rescisão, (a) os direitos e licenças concedidos a você nestes termos
        terminarão; (b) você deve parar de usar o aplicativo e (se necessário)
        excluí-lo do seu dispositivo.
      </p>
      <p>
        <strong>Alterações a estes Termos e Condições</strong>
      </p>
      <p>
        Podemos atualizar nossos Termos e Condições de tempos em tempos. Assim,
        recomendamos que você revise esta página periodicamente para quaisquer
        alterações. Notificaremos você sobre quaisquer alterações publicando os
        novos Termos e Condições nesta página.
      </p>
      <p>Estes termos e condições estão em vigor a partir de 2022-04-06</p>
      <h1>Betafit Política de Privacidade</h1>
      <p>
        Betabit construiu o aplicativo Betafit como um aplicativo comercial.
        Este SERVIÇO é fornecido pela Betabit e destina-se ao uso como está.
      </p>
      <p>
        Esta página é usada para informar os visitantes sobre nossas políticas
        de coleta, uso e divulgação de Informações Pessoais se alguém decidir
        usar nosso Serviço.
      </p>
      <p>
        Se você optar por usar nosso Serviço, concorda com a coleta e uso de
        informações em relação a esta política. As Informações Pessoais que
        coletamos são usadas para fornecer e melhorar o Serviço. Não usaremos ou
        compartilharemos suas informações com ninguém, exceto conforme descrito
        nesta Política de Privacidade.
      </p>
      <p>
        Os termos usados ​​nesta Política de Privacidade têm os mesmos
        significados que em nossos Termos e Condições, que são acessíveis na
        Betafit, salvo definição em contrário nesta Política de Privacidade.
      </p>
      <p>
        <strong>Coleta e uso de informações</strong>
      </p>
      <p>
        Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que
        você nos forneça certas informações de identificação pessoal, incluindo,
        entre outras, imagens fotográficas, nome, data de nascimento, e-mail,
        galeria, etc. As informações que solicitamos serão retidas por nós e
        usadas conforme descrito nesta política de privacidade.
      </p>
      <div>
        <p>
          O aplicativo usa serviços de terceiros que podem coletar informações
          usadas para identificá-lo.
        </p>
        <p>
          Link para a política de privacidade de provedores de serviços
          terceirizados usados ​​pelo aplicativo
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
      </div>
      <p>
        <strong>Dados de registro</strong>
      </p>
      <p>
        Queremos informá-lo que sempre que você usar nosso Serviço, em caso de
        erro no aplicativo, coletamos dados e informações (através de produtos
        de terceiros) em seu telefone chamado Log Data. Esses Dados de Registro
        podem incluir informações como o endereço do Protocolo de Internet
        (“IP”) do seu dispositivo, nome do dispositivo, versão do sistema
        operacional, a configuração do aplicativo ao utilizar nosso Serviço, a
        hora e a data de seu uso do Serviço e outras estatísticas .
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Cookies são arquivos com uma pequena quantidade de dados que são
        comumente usados ​​como identificadores exclusivos anônimos. Eles são
        enviados para o seu navegador a partir dos sites que você visita e são
        armazenados na memória interna do seu dispositivo.
      </p>
      <p>
        Este Serviço não usa esses “cookies” explicitamente. No entanto, o
        aplicativo pode usar código de terceiros e bibliotecas que usam
        “cookies” para coletar informações e melhorar seus serviços. Você tem a
        opção de aceitar ou recusar esses cookies e saber quando um cookie está
        sendo enviado ao seu dispositivo. Se você optar por recusar nossos
        cookies, talvez não consiga usar algumas partes deste Serviço.
      </p>
      <p>
        <strong>Provedores de serviço</strong>
      </p>
      <p>
        Podemos empregar empresas e indivíduos terceirizados devido aos
        seguintes motivos:
      </p>
      <ul>
        <li>Para facilitar nosso Serviço;</li>
        <li>Para fornecer o Serviço em nosso nome;</li>
        <li>Para executar serviços relacionados ao Serviço; ou</li>
        <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
      </ul>
      <p>
        Queremos informar os usuários deste Serviço que esses terceiros têm
        acesso às suas Informações Pessoais. O motivo é realizar as tarefas
        atribuídas a eles em nosso nome. No entanto, eles são obrigados a não
        divulgar ou usar as informações para qualquer outra finalidade.
      </p>
      <p>
        <strong>Segurança</strong>
      </p>
      <p>
        Valorizamos sua confiança em nos fornecer suas informações pessoais,
        portanto, estamos nos esforçando para usar meios comercialmente
        aceitáveis ​​de protegê-las. Mas lembre-se que nenhum método de
        transmissão pela internet, ou método de armazenamento eletrônico é 100%
        seguro e confiável, e não podemos garantir sua segurança absoluta.
      </p>
      <p>
        <strong>Links para outros sites</strong>
      </p>
      <p>
        Este Serviço pode conter links para outros sites. Se você clicar em um
        link de terceiros, será direcionado para esse site. Observe que esses
        sites externos não são operados por nós. Portanto, recomendamos que você
        revise a Política de Privacidade desses sites. Não temos controle e não
        assumimos responsabilidade pelo conteúdo, políticas de privacidade ou
        práticas de quaisquer sites ou serviços de terceiros.
      </p>
      <p>
        <strong>Privacidade infantil</strong>
      </p>
      <div>
        <p>
          Esses Serviços não atendem a menores de 13 anos. Não coletamos
          intencionalmente informações de identificação pessoal de crianças
          menores de 13 anos. No caso de descobrirmos que uma criança menor de
          13 anos nos forneceu informações pessoais, excluímos imediatamente de
          nossos servidores. Se você é pai ou responsável e está ciente de que
          seu filho nos forneceu informações pessoais, entre em contato conosco
          para que possamos tomar as medidas necessárias.
        </p>
      </div>
      <p>
        <strong>Alterações nesta Política de Privacidade</strong>
      </p>
      <p>
        Podemos atualizar nossa Política de Privacidade de tempos em tempos.
        Assim, recomendamos que você revise esta página periodicamente para
        quaisquer alterações. Notificaremos você sobre quaisquer alterações
        publicando a nova Política de Privacidade nesta página.
      </p>
      <p>Esta política está em vigor a partir de 2022-04-06</p>
      <p>
        <strong>Contate-Nos</strong>
      </p>
      <p>
        Se você tiver alguma dúvida ou sugestão sobre nossa Política de
        Privacidade, não hesite em nos contatar em betabitsistemas@gmail.com.
      </p>
      <p>Créditos: privacypolicytemplate.net</p>
      </SPageBody>
  );
};

export default PrivacyTerms;
