import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../services/api";
import { RootState } from "../../store";
import { ICompany } from "../../store/interfaces";
import {
  setActive,
  setCompanyId,
  setEmail,
  setModality,
  setName,
} from "../../store/reducers/userFormReducer";
import InputModal from "../Modal/inputsModal";
import { SDiv, SLabel } from "../Modal/styles";
import { SSelect } from "../Modal/styles";

interface UserFormProps {
  id?: string;
  name?: string;
  email?: string;
  companyId?: string;
  modality?: string;
  active?: boolean;
  action?: string;
}

const UserForm: React.FC<UserFormProps> = ({
  name,
  email,
  modality,
  active,
  action,
  companyId,
}) => {
  // const admin = useSelector((state: RootState) => state.auth.admin);
  const admin = useSelector((state: RootState) => state.companies.admin);
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const simpleGetCompanies = async () => {
      if (admin) {
        const response = await API.simpleGetManyCompanies();
        setCompanies(response.data.body.data);
      }
      return;
    };
    simpleGetCompanies();
  }, [admin]);

  return (
    <form>
      <InputModal
        htmlId="name"
        field="Nome"
        value={name}
        setOnChange={(e) => {
          dispatch(setName(e.target.value));
        }}
      />
      <InputModal
        htmlId="email"
        field="Email"
        value={email}
        setOnChange={(e) => {
          dispatch(setEmail(e.target.value));
        }}
      />
      <InputModal
        htmlId="modality"
        field="Modalidade"
        value={modality}
        setOnChange={(e) => {
          dispatch(setModality(e.target.value));
        }}
      />
      {action === "edit" ? null : admin ? (
        <SDiv>
          <SLabel>Empresas</SLabel>
          <SSelect
            id="type"
            value={companyId}
            onChange={(e) => {
              dispatch(setCompanyId(e.target.value));
            }}
            defaultValue="none"
          >
            <option value="none" selected>
              Escolha uma empresa
            </option>
            {companies.map((company: ICompany) => (
              <>
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              </>
            ))}
          </SSelect>
        </SDiv>
      ) : null}
      {action === "edit" ? (
        <InputModal
          htmlId="active"
          field="Ativo"
          value={active ? "true" : "false"}
          selectType={true}
          setOnChangeSelect={(e) => {
            dispatch(setActive(e.target.value === "true"));
          }}
        />
      ) : null}
    </form>
  );
};

export default UserForm;
