import React, { Dispatch, MouseEventHandler, useEffect } from "react";
import theme from "../../config/theme";
import Button, { IconButton, SecondaryButton } from "../Button";
import { AiOutlineClose } from "react-icons/ai";
import useScrollLock from "../../hooks/useScrollLock";
import {
  SModalActions,
  SModalBody,
  SModalContainer,
  SModalHeader,
  SModalOverlay,
  SModalTitle,
} from "./styles";

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  title: string;
  cancelText?: string;
  actionText: string;
  action: MouseEventHandler<HTMLButtonElement> | undefined;
}

const Modal: React.FC<ModalProps> = ({
  open,
  setOpen,
  title,
  cancelText = "Cancelar",
  actionText,
  action,
  children,
}) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open, lockScroll, unlockScroll]);

  if (!open) return null;

  return (
    <React.Fragment>
      <SModalOverlay onClick={() => setOpen(false)} />
      <SModalContainer>
        <SModalHeader>
          <SModalTitle>{title}</SModalTitle>
          <IconButton
            iconComponent={<AiOutlineClose color={theme.text.primary.normal} />}
            onClick={() => setOpen(false)}
          />
        </SModalHeader>
        <SModalBody>{children}</SModalBody>
        <SModalActions>
          <SecondaryButton text={cancelText} onClick={() => setOpen(false)} />
          <Button text={actionText} onClick={action} />
        </SModalActions>
      </SModalContainer>
    </React.Fragment>
  );
};

export default Modal;
