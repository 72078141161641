import { configureStore } from "@reduxjs/toolkit";
import albumsReducer from "./reducers/albumsReducer";
import appReducer from "./reducers/appReducer";
import authReducer from "./reducers/authReducer";
import companiesReducer from "./reducers/companiesReducer";
import companyFormReducer from "./reducers/companyFormReducer";
import companyReducer from "./reducers/companyReducer";
import userFormReducer from "./reducers/userFormReducer";
import usersReducer from "./reducers/usersReducer";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    company: companyReducer,
    companies: companiesReducer,
    users: usersReducer,
    userForm: userFormReducer,
    companyForm: companyFormReducer,
    albums: albumsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
