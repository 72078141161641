import { ReactElement } from "react";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Users from "../pages/Users";
import Albums from "../pages/Albums";
import Companies from "../pages/Companies";

export interface IRoutes<T> {
  path: string;
  component: ReactElement<T>;
}

// ordem importa!
const routes: IRoutes<any>[] = [
  {
    path: "/albums",
    component: <Albums />,
  },
  {
    path: "/users",
    component: <Users />,
  },
  {
    path: "/companies",
    component: <Companies />,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

export default routes;
