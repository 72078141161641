import React, { MouseEventHandler, ReactElement } from "react";
import {
  SButton,
  SFabTextButton,
  SFabButton,
  STextButton,
  SSecondaryButton,
  SLoginButton,
  STextButtonPrivacyPolicy,
  STableButton,
  STextCompanyButton,
  SDiv,
} from "./styles";

interface ButtonProps {
  text: string | number;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}

interface IconButtonProps extends Omit<ButtonProps, "text"> {
  text?: string | null;
  iconComponent?: ReactElement<any, any>;
}

export const Button: React.FC<ButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <SButton onClick={onClick} disabled={disabled}>
      {text}
    </SButton>
  );
};

export const SecondaryButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <SSecondaryButton onClick={onClick} disabled={disabled}>
      {text}
    </SSecondaryButton>
  );
};

export const IconTextCompanyButton: React.FC<IconButtonProps> = ({
  iconComponent,
  text,
  onClick,
  disabled,
}) => {
  return (
    <STextCompanyButton onClick={onClick} disabled={disabled}>
      <SDiv>{iconComponent}</SDiv>
      <SDiv>{text}</SDiv>
    </STextCompanyButton>
  );
};

export const TextButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <STextButton onClick={onClick} disabled={disabled}>
      {text}
    </STextButton>
  );
};

export const IconButton: React.FC<IconButtonProps> = ({
  iconComponent,
  onClick,
  disabled,
}) => {
  return (
    <SFabButton onClick={onClick} disabled={disabled}>
      {iconComponent}
    </SFabButton>
  );
};

export const FabButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <SFabButton onClick={onClick} disabled={disabled}>
      {text}
    </SFabButton>
  );
};

export const FabTextButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <SFabTextButton onClick={onClick} disabled={disabled}>
      {text}
    </SFabTextButton>
  );
};

export const LoginButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <SLoginButton onClick={onClick} disabled={disabled}>
      {text}
    </SLoginButton>
  );
};

export const TextButtonPrivacyPolicy: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <STextButtonPrivacyPolicy onClick={onClick} disabled={disabled}>
      {text}
    </STextButtonPrivacyPolicy>
  );
};

export const TableButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <STableButton onClick={onClick} disabled={disabled}>
      {text}
    </STableButton>
  );
};
export default Button;
