import React, { useState } from "react";
import { LoginButton } from "../Button";
import {
  SCheckBoxInput,
  SDivLoginButton,
  SFormLogin,
  SImg,
  SDivInputLogin,
  SDivPrivacyPolicy,
  SLinkForgotPassword,
  SLink,
  SDivForgotPassword,
  SDivInputLoginPass,
  STextInputPass,
  SSpan,
} from "./styles";
import LogoLogin from "../../assets/LogoLogin.svg";
import useAuth from "../../hooks/useAuth";
import TextInput from "./TextInput";
import { toast } from "react-toastify";
import OutlineEye from "../OutlineEye";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeInputPassword, setTypeInputPassword] = useState(true);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  const handleSubmit = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    setLoading(true);
    if (email.length < 1) {
      toast.warn("Email não pode ser vazio!");
      setLoading(false);
      setEmail("");
      setPassword("");
      return;
    }

    if (password.length < 8) {
      toast.warn("Senha deve conter um mínimo de 8 caracteres!");
      setLoading(false);
      return;
    }

    if (password.length > 40) {
      toast.warn("Senha deve conter um máximo de 40 caracteres!");
      setLoading(false);
      return;
    }

    if (!check) {
      toast.error("Aceite os termos de uso e Politica de Privacidade!");
      setLoading(false);
      return;
    }

    const responseLogin = await login(email, password);
    if (!responseLogin) {
      toast.error("Email ou senha incorreta!");
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <SFormLogin onSubmit={handleSubmit}>
      <SImg src={LogoLogin} alt="" />
      <SDivInputLogin>
        <TextInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </SDivInputLogin>
      <SDivInputLoginPass>
        <STextInputPass
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Senha"
          type={typeInputPassword ? "password" : "text"}
          required={true}
        />
        <OutlineEye
          setTypeInputPassword={setTypeInputPassword}
          typeInputPassword={typeInputPassword}
        />
      </SDivInputLoginPass>
      <SDivPrivacyPolicy>
        <SCheckBoxInput
          type="checkbox"
          onChange={() => setCheck(!check ? true : false)}
          id="policy"
        />
        <SSpan>Li e concordo com os</SSpan>
        <SLink href="/privacy-terms">
          Termos de uso e Política de privacidade
        </SLink>
      </SDivPrivacyPolicy>
      <SDivForgotPassword>
        <SLinkForgotPassword href="/forgot-password">
          Esqueci minha senha
        </SLinkForgotPassword>
      </SDivForgotPassword>
      <SDivLoginButton>
        <LoginButton
          text={loading ? "Carregando..." : "Login"}
          disabled={loading ? true : false}
          onClick={(e) => handleSubmit(e)}
        />
      </SDivLoginButton>
    </SFormLogin>
  );
};

export default LoginForm;
