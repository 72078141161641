import styled from "styled-components";
import DEFAULTS from "../../config/defaults";
import theme from "../../config/theme";

export const SContainer = styled.header`
  position: fixed;
  background-color: ${theme.bg.normal};
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.5);
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const SContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 99%;
  min-width: 800px;
  height: ${DEFAULTS.headerHeight};
`;

export const SDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const SLink = styled.a`
  font-size: 1.1rem;
  color: ${theme.text.link.normal};
  text-decoration: none;
`;
