import React from "react";

const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "10px";
    const header: HTMLElement | null = document.querySelector("#header");
    if (header) {
      header.style.paddingRight = "9px";
    }
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
    const header: HTMLElement | null = document.querySelector("#header");
    if (header) {
      header.style.paddingRight = "0px";
    }
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export default useScrollLock;
