import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "../interfaces";

export interface IUserForm {
  id: string;
  name: string;
  email: string;
  modality: string;
  companyId: string | ICompany;
  active: boolean;
}

export const initialState: IUserForm = {
  id: "",
  name: "",
  email: "",
  companyId: "",
  modality: "",
  active: true,
};

export const userFormSlice = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setCompany: (state, action: PayloadAction<ICompany>) => {
      state.companyId = action.payload;
    },
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload;
    },
    setModality: (state, action: PayloadAction<string>) => {
      state.modality = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    resetUser: (state) => {
      // state = initialState;
      state.id = "";
      state.name = "";
      state.email = "";
      state.modality = "";
      state.active = true;
    },
  },
});

export const {
  setUserId,
  setName,
  setEmail,
  setModality,
  setCompany,
  setCompanyId,
  setActive,
  resetUser,
} = userFormSlice.actions;

export default userFormSlice.reducer;
