import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
  token: string | null;
  admin: boolean;
}

export const initialState: IAuthState = {
  token: localStorage.getItem("accessToken"),
  admin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = action.payload;
    },
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.admin = action.payload;
    },
  },
});

export const { setToken, setAdmin } = authSlice.actions;

export default authSlice.reducer;
