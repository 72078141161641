import React from "react";
import styled from "styled-components";
import notFound from "../assets/notFound.svg";
import Button from "../components/Button";

interface NotFoundProps {}

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-items: center;
  align-items: center;
`;
export const SDiv = styled.div``;
export const SImg = styled.img`
  width: 50%;
`;

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <SContainer>
      <SImg src={notFound} alt="Home" />
      <SDiv>
        <Button
          text="Voltar"
          onClick={() => {
            window.history.back();
          }}
        />
      </SDiv>
    </SContainer>
  );
};

export default NotFound;
