import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlan } from "../interfaces";

export interface ICompanyForm {
  id: string;
  name: string;
  email: string;
  password: string;
  token: string;
  plan?: IPlan | string;
  rewardValue: string;
  imagesPerReward: number;
  active: boolean;
}

export const initialState: ICompanyForm = {
  id: "",
  name: "",
  email: "",
  password: "",
  token: "",
  rewardValue: "",
  imagesPerReward: 0,
  active: true,
};

export const companyFormSlice = createSlice({
  name: "companyForm",
  initialState,
  reducers: {
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setPlan: (state, action: PayloadAction<IPlan>) => {
      state.plan = action.payload;
    },
    setPlanId: (state, action: PayloadAction<string>) => {
      state.plan = action.payload;
    },
    setRewardValue: (state, action: PayloadAction<string>) => {
      state.rewardValue = action.payload;
    },
    setImagesPerReward: (state, action: PayloadAction<number>) => {
      state.imagesPerReward = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    resetCompany: (state) => {
      // state = initialState;
      state.id = "";
      state.name = "";
      state.email = "";
      state.token = "";
      state.plan = "";
      state.rewardValue = "";
      state.imagesPerReward = 0;
      state.active = true;
    },
  },
});

export const {
  setCompanyId,
  setName,
  setEmail,
  setToken,
  setRewardValue,
  setImagesPerReward,
  setActive,
  resetCompany,
  setPlan,
  setPassword,
  setPlanId,
} = companyFormSlice.actions;

export default companyFormSlice.reducer;
