import styled from "styled-components";
import theme from "../../config/theme";

export const SPageContainer = styled.div`
  width: 100%;
`;

export const STable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const STHead = styled.thead``;

export const STBody = styled.tbody``;

export const STr = styled.tr`
  border-bottom: 1px solid ${theme.shadow.light};
`;

export const STh = styled.th`
  text-align: left;
  padding: 10px 0;

  &:last-child {
    text-align: end;
  }
`;

interface ItdProps {
  limit?: string;
}

export const STd = styled.td<ItdProps>`
  text-align: left;
  color: ${(props) => props.limit};
  padding: 10px 0;
  &:last-child {
    text-align: end;
  }
`;
